import { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const InputField = forwardRef(
  (
    { label, className, inputStyle, labelStyle, error, errorMessage = null, errorStyle, ...props },
    ref
  ) => {
    return (
      <div className={`${className}`}>
        {label && (
          <label
            htmlFor="mobile"
            className={` my-2 text-xs capitalize text-gray-dark ${labelStyle}`}
          >
            {label} {props?.required && '*'}
          </label>
        )}
        <input
          {...props}
          {...(ref && { ref })}
          className={`${inputStyle} block w-full rounded-sm border border-gray-border p-3 !font-satoshi-regular font-thin text-black outline-none placeholder:capitalize sm:text-sm`}
        />
        {error && (
          <p className={`${errorStyle} ml-1 mt-1 text-xs text-red md:text-sm`}>
            {errorMessage ?? error}
          </p>
        )}
      </div>
    );
  }
);

export default InputField;
