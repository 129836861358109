import App from 'next/app';
import axios from 'axios';
import ExternalScripts from '../components/shared/ExternalScripts';
import { useRouter } from 'next/router';
import '../styles/globals.css';
import '../styles/custom.css';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { PageComponent } from '../components/shared';
import Script from 'next/script';
import { MIXPANEL_PROJECT_TOKEN, UET_ID } from '@lib/analytics/analytics.config';
import { getStrapiURL } from '../lib/api';
import { useEffect, useState } from 'react';
import { StoreProvider } from '../globalStore/storeProvider';
import { reducer, initialState } from 'globalStore/reducer';
import { usePreviousRoute } from '@components/helper/hooks/usePreviousRoute';
const apiBaseUrl = getStrapiURL();

import { MixPanel } from '@lib/analytics/analytics.service';

function MyApp({ Component, pageProps, globalProps, coursesSummary }) {
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const router = useRouter();
  const previousRoute = usePreviousRoute();

  // Handle route change start
  const handleRouteChangeStart = () => {
    setIsLoading(true); // Show the loader
  };

  // Handle route change complete
  const handleRouteChangeComplete = () => {
    setIsLoading(false); // Hide the loader
  };

  useEffect(() => {
    if (MIXPANEL_PROJECT_TOKEN) {
      MixPanel.init(MIXPANEL_PROJECT_TOKEN);
    }

    // Add event listeners for route change start and complete
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      // Cleanup event listeners on component unmount
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  return (
    <>
      {/* BEGIN: Microsoft Advertising UET Javascript tag */}
      <Script
        id="microsoft-uet-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function (w, d, t, r, u) {
            var f, n, i;
            (w[u] = w[u] || []),
              (f = function () {
                var o = { ti: '${UET_ID}' };
                (o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad');
              }),
              (n = d.createElement(t)),
              (n.src = r),
              (n.async = 1),
              (n.onload = n.onreadystatechange =
                function () {
                  var s = this.readyState;
                  (s && s !== 'loaded' && s !== 'complete') ||
                    (f(), (n.onload = n.onreadystatechange = null));
                }),
              (i = d.getElementsByTagName(t)[0]),
              i.parentNode.insertBefore(n, i);
          })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
        `,
        }}
      />
      {/* END: Microsoft Advertising UET Javascript tag */}

      <ExternalScripts />
      {isLoading && (
        <div className="pointer-events-none fixed inset-0 z-[11111] flex items-center justify-center">
          <AiOutlineLoading3Quarters size={70} className="animate-spin text-[#F00037]" />
        </div>
      )}
      <StoreProvider initialState={initialState} reducer={reducer}>
        <PageComponent globalProps={globalProps} coursesSummary={coursesSummary}>
          {/* Google Tag Manager (noscript) */}
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-KD4ZRVR"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
          {/* End Google Tag Manager (noscript) */}
          <Component {...pageProps} />
        </PageComponent>
      </StoreProvider>
    </>
  );
}

export default MyApp;

MyApp.getInitialProps = async (ctx) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(ctx);
  // Fetch global site settings from Strapi
  const { data } = await axios.get(
    `${apiBaseUrl}/api/global?populate=AboutHeader.innerColumn&populate=Footer.innerColumn&populate=newCompanyLogo&populate=newCompanyLogoDark&populate=companyContact&populate=SocialLinks&populate=PromotionHeader&populate=seo&populate=placementReport`
  );
  const { data: coursesSummary } = await axios.get(`${apiBaseUrl}/api/all-courses-summary`);
  // Pass the data to our page via props
  return { ...appProps, globalProps: data.data.attributes, coursesSummary, revalidate: 100000 };
};
