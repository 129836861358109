import cn from 'classnames';

const SelectField = ({
  className,
  label,
  children,
  selectStyle,
  error,
  errorStyle,
  placeholder,
  ...props
}) => {
  return (
    <div className={cn(className)}>
      {label && (
        <label htmlFor="mobile" className="my-2 text-xs capitalize text-gray-dark">
          {label} {props?.required && '*'}
        </label>
      )}
      <section className="relative">
        <select
          {...props}
          className={`${selectStyle} block w-full appearance-none rounded-sm border border-gray-border p-3 !font-satoshi-regular font-thin outline-none placeholder:capitalize sm:text-sm`}
        >
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {children}
        </select>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="absolute right-1 top-1/2 bottom-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 text-gray"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
      </section>
      {error && <p className={cn(`ml-1 mt-1 text-xs text-red md:text-sm`, errorStyle)}>{error}</p>}
    </div>
  );
};

export default SelectField;
