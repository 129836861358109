import { eventDateTimeFormat } from '@utils/functions/readingTime';
import { genRegisterRandomNumber } from '@utils/functions/throttle';
import { DateTimeFormatter } from '@utils/utils';
import React from 'react';

function EmbedEventFormHeader({ eventData }) {
  const eventStartTime = eventDateTimeFormat(eventData.eventDate, eventData.startTime);
  const registeredCount = genRegisterRandomNumber(eventStartTime);

  return (
    <div className="flex flex-col gap-y-[16px] pr-[24px]">
      <p className="font-satoshi-medium text-lg font-semibold text-gray-800">{eventData.title}</p>
      <div className="flex items-center justify-between">
        <p className="font-satoshi-medium text-[14px] leading-5 text-gray-navigation">
          Date: {DateTimeFormatter(eventStartTime, 'MMM DD, YYYY')}
        </p>
        <p className="font-satoshi-medium text-[14px] leading-5 text-gray-navigation">
          Hurry Up! Only{' '}
          <span className="font-satoshi-bold font-bold  text-red">
            {registeredCount} seats left.
          </span>
        </p>
      </div>
    </div>
  );
}

export default EmbedEventFormHeader;
