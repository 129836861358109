import { sourceType } from '@components/shared/widgets/ContactUs';
import { useStore } from '@globalStore/storeProvider';
import { getGrowSignupUrl } from 'config';
import Image from 'next/image';
import { useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { isSuccessScreen } from '@components/shared/widgets/LeadCapturePopup';
import { WA_LINK } from '@lib/constants';

const CallbackSuccess = ({
  isWhatsApp,
  email = null,
  isDownloadBrochure = false,
  isEventRegistration = false,
  isEventRegistrationDesktop = false,
  isPlacementReport = false,
  eventData = null,
}) => {
  const { widgetSource, brochureLink, placementReportLink } = useStore();

  const isOneClickRegistration = eventData != null;

  const downloadBrouchure = async () => {
    try {
      const FILE_URL = isPlacementReport ? placementReportLink : brochureLink;
      const response = await axios.get(FILE_URL, {
        responseType: 'blob',
      });
      const blob = response.data;

      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        (brochureLink || placementReportLink || 'Brouchure.pdf')
          .replace(/.*\//, '')
          .replace(/\+/g, ' ')
      );
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.dispatchEvent(new MouseEvent('click'));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (widgetSource === sourceType.brouchure || isPlacementReport) {
      downloadBrouchure();
    }

    if (isSuccessScreen.current) {
      isSuccessScreen.current.children[1].style.display = 'none';
      isSuccessScreen.current.children[2].style.height = '100%';
    }
  }, []);

  return (
    <>
      <section
        className={`flex flex-col items-center justify-center pt-4 ${
          isEventRegistration ? 'mt-4 bg-transparent' : 'bg-white'
        } ${isSuccessScreen.current ? 'py-2' : ''} pb-2`}
      >
        {isEventRegistration ? (
          <p>
            {' '}
            <Image
              src="/assets/gifs/success.gif"
              alt="registrationSuccess"
              height={100}
              width={100}
              loading="lazy"
            />
          </p>
        ) : isOneClickRegistration ? (
          <div className="flex w-full flex-col gap-y-3 px-4">
            <h5 className="flex items-center gap-x-3 font-satoshi-bold text-xl font-bold ">
              <img src="/assets/icons/megaphone.png" alt="" /> Event registration successful
            </h5>
            <div className="flex flex-col items-start rounded bg-gradient-to-r from-[#404041] to-[#202021] px-4 py-3 text-white">
              <p className="font-satoshi-medium text-lg font-medium ">{eventData?.title}</p>
              <p className="flex items-center gap-x-2 font-satoshi-medium text-sm font-medium ">
                <img src="/assets/icons/calender.png" alt="" />{' '}
                {dayjs(eventData?.startTime).format('MMM DD | hh mm A')}
              </p>
            </div>
          </div>
        ) : (
          <>
            <h5 className="font-satoshi-bold text-5xl font-bold  ">🙋🏼‍♂️</h5>
            <h5 className="font-satoshi-bold text-2xl font-bold  ">Thank You!!!</h5>
          </>
        )}

        <div className="flex flex-col items-center justify-center gap-y-2 pt-3 text-center">
          <p
            className={`${
              !isEventRegistrationDesktop && 'w-11/12'
            } font-satoshi-medium  text-[0.9rem] font-light leading-5 tracking-normal text-gray-dark`}
          >
            {isWhatsApp ? (
              'Thanks for sharing your details with us. Please click on link below to chat with us.'
            ) : isDownloadBrochure ? (
              'Thanks for downloading the brochure'
            ) : isEventRegistration ? (
              <span className="bold flex items-center space-x-2 font-satoshi-medium text-base font-medium ">
                You have successfully registered for the event. We will send you a reminder to join
                before the scheduled time.{' '}
              </span>
            ) : (
              !isOneClickRegistration &&
              'We have received your callback request. Our academic counsellor will reach out to you within the next 24 hours. '
            )}
          </p>
          <section
            className={` space-y-5 ${
              isEventRegistration ? 'mt-2 bg-transparent' : 'mt-5 w-11/12 bg-peach'
            } font-satoshi-medium md:p-5 ${isWhatsApp && 'hidden'}`}
          >
            {!isWhatsApp && (
              <>
                <p
                  className={`flex items-center space-x-2 text-base ${
                    isEventRegistrationDesktop && 'font-satoshi-medium font-medium '
                  }`}
                >
                  {isEventRegistration ? (
                    'Meanwhile, You can explore our student’s dashboard and start learning for free.'
                  ) : (
                    <>
                      <span className="mr-1.5 text-3xl">🚀</span> In the meantime, sign up and take
                      a free tour of our program!
                    </>
                  )}
                </p>
                <button
                  onClick={() => {
                    email?.match(/@([^.]+)\./)[1] === 'gmail'
                      ? window?.open(`${getGrowSignupUrl()}?source=rcb_success`, '_blank')
                      : window?.open(getGrowSignupUrl(), '_blank');
                  }}
                  className={`rounded-sm  bg-red px-6 ${
                    isEventRegistration ? 'py-1.5' : 'py-2.5'
                  }  font-normal text-white`}
                >
                  Start learning for free
                </button>
              </>
            )}
          </section>

          {isWhatsApp ? (
            <a
              href={`${WA_LINK}?text=hey`}
              target="_blank"
              rel="noreferrer"
              className={`mt-4 flex w-8/12 items-center justify-center rounded-sm bg-[#3FC250] px-4 py-2 font-satoshi-medium font-medium  capitalize text-white disabled:opacity-80`}
            >
              <Image alt="whatsapp" src="/assets/icons/whatsapp.svg" height={30} width={30} />
              <span className="ml-2">Whatsapp us</span>
            </a>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default CallbackSuccess;
