import mixpanel from 'mixpanel-browser';
const isWindowDefined = () => typeof window !== 'undefined';

export const loginToAnalyticsSvc = (id) => {
  // id must be hashedEmail
  MixPanel.identifyUser(id);
  if (isWindowDefined()) {
    // track some events
    window?.gtag('set', { user_id: id || 'anonymous' });
  }
};
export const setAttributesInAnalytics = (name, email, phone) => {
  MixPanel.setUserAttributes({ name, email });
  if (isWindowDefined()) {
    //setting user attributes
    if (!window.gtag) return;
    window.gtag('set', { email: email });
    if (name) {
      if (!window.gtag) return;
      window.gtag('set', { name: name });
    }
    if (phone) {
      if (!window.gtag) return;
      window.gtag('set', { phone: phone });
    }
  }
};

export const trackEvent = (eventName, eventData) => {
  if (isWindowDefined()) {
    if (!window.gtag) return;
    window.gtag('event', eventName, eventData);
  }
};

export class MixPanel {
  static init(mixpanelId) {
    mixpanel.init(mixpanelId, {
      track_pageview: 'full-url',
      persistence: 'localStorage',
      api_host: 'https://phaseshift.almagrow.com',
    });
  }

  static identifyUser(userId) {
    mixpanel.identify(userId);
  }

  static setUserAttributes({ name, email }) {
    mixpanel.people.set({
      $name: name,
      $email: email,
    });
  }

  static reset() {
    mixpanel.reset();
  }
}
