// Button.stories.js
import { Badge } from './Badge';

export default {
  title: 'Example/Badge',
  component: Badge,
  parameters: {
    layout: 'centered',
  },
  tags: ['autodocs'],
  argTypes: {
    backgroundColor: { control: 'color' },
  },
};

const Template = (args) => <Badge {...args} />;

const PrimaryAgrs = {
  primary: true,
  label: 'Label',
};

export const PrimaryBadge = (props) => <Template {...PrimaryAgrs} {...props} />;

const SecondaryArgs = {
  primary: false,
  label: 'Label',
};

export const SecondaryBadge = (props) => <Template {...SecondaryArgs} {...props} />;

export const CustomBadge = (props) => <Template {...props} primary={false} />;
