import { emailRegex } from 'config';
import { nameRegex } from 'config';

export const formValidation = (formData) => {
  const error = {};

  if (!formData.name) {
    error.name = 'Name is required';
  }

  if (formData.name && formData.name.length > 100) {
    error.name = 'Name should be less than 100 characters';
  }

  if (formData.name && !nameRegex.test(formData.name)) {
    error.name = 'Name should contain only alphabets';
  }

  if (!formData.email) {
    error.email = 'Email is required';
  }

  if (formData.email && !emailRegex.test(formData.email)) {
    error.email = 'Invalid email';
  }

  if (!formData.graduationYear) {
    error.graduationYear = 'Graduation year is required';
  }

  if (!formData.phone) {
    error.phone = 'Phone number is required';
  }

  if (formData.phone && String(formData.phone).length !== 10) {
    error.phone = 'Phone number should be of 10 digits';
  }

  if (!formData.termNCondition) {
    error.termNCondition = 'Please accept the terms and conditions';
  }

  return error;
};

export const otpFormValidation = (formData) => {
  const errors = {};

  if (!formData.phone) {
    errors.phone = 'Phone number is required';
  }

  if (formData.phone && String(formData.phone).length !== 10) {
    errors.phone = 'Phone number should be of 10 digits';
  }

  if (!formData.otp) {
    errors.otp = 'OTP is required';
  }

  if (formData.otp && formData.otp.length !== 6) {
    errors.otp = 'OTP should be of 6 digits';
  }

  return errors;
};
