import cn from 'classnames';

export function PhoneField({
  className,
  label,
  countryCodeStyle,
  phoneInputContainerStyle,
  error,
  errorMessage,
  errorStyle,
  labelStyle,
  ...props
}) {
  return (
    <div className="flex flex-col gap-y-[4px]">
      {label && <label className={cn(labelStyle)}>{label}</label>}
      <div>
        <div className={cn('grid grid-cols-8 items-center gap-x-[16px]', className)}>
          <input
            value="+91"
            disabled
            className={cn(
              'col-span-1 bg-transparent !font-satoshi-regular font-thin text-black',
              countryCodeStyle
            )}
          />

          <div className="col-span-7">
            <input
              {...props}
              className={cn(
                '!font-satoshi-regular font-thin text-black outline-none',
                phoneInputContainerStyle
              )}
              type="tel"
            />
          </div>
        </div>
        {error && (
          <p className={cn(errorStyle, 'ml-1 mt-1 text-xs text-red md:text-sm')}>
            {errorMessage ?? error}
          </p>
        )}
      </div>
    </div>
  );
}
