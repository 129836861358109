import React, { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { Primary } from 'stories/Button.stories';
import { Checkbox } from 'stories/Checkbox';
import InputField from 'stories/InputField';
import { PhoneField } from 'stories/PhoneField';
import { RadioButtonGroup } from 'stories/RadioButtonGroup';
import SelectField from 'stories/SelectField';
import { useStore } from '@globalStore/storeProvider';

function GlobalForm({
  formLayout,
  formData,
  fields = [],
  buttonCTA,
  changeHandler,
  onSubmit,
  validate,
}) {
  const [errors, setErrors] = useState({});
  const { courseInterest } = useStore();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    return () => {
      setIsSubmitting(null);
      setErrors(null);
    };
  }, []);

  const SubmitHandler = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      const formErrors = validate(formData);

      if (Object.keys(formErrors).length === 0) {
        await onSubmit(formData);
      } else {
        setErrors(formErrors);
      }
    } catch (err) {
      setErrors({ submit: err?.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const pauseContactModal = () => {
    const isPopupViewed = sessionStorage.getItem('popup_viewed');
    if (!isPopupViewed) {
      sessionStorage.setItem('by_form_input', true);
      sessionStorage.setItem('popup_viewed', true);
    }
  };

  const ShouldRenderField = useCallback(
    (field) => {
      const graduationYear = formData['graduationYear'];

      if (field.name === 'background') {
        return graduationYear && graduationYear >= currentYear;
      }

      if (field.name === 'userType') {
        return graduationYear && graduationYear < currentYear;
      }

      if (field.name === 'courseInterest') {
        return !courseInterest;
      }

      return true;
    },
    [formData.graduationYear]
  );

  return (
    <form className={formLayout} onSubmit={SubmitHandler}>
      {fields.map(({ type, ...field }) => {
        if (!ShouldRenderField(field)) return null;

        if (type === 'select') {
          return (
            <SelectField
              key={field.name}
              name={field.name}
              selectStyle="bg-white !rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 invalid:text-gray-400 text-gray-900"
              value={formData[field.name]}
              placeholder={field.placeholder}
              onChange={changeHandler}
              error={errors[field.name]}
              required
            >
              {field.options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectField>
          );
        }

        if (type === 'radio') {
          return (
            <RadioButtonGroup
              key={field.name}
              name={field.name}
              options={field.options}
              groupLabel={field?.label}
              onChange={changeHandler}
              value={formData[field.name]}
              required
            />
          );
        }

        if (type === 'tel') {
          return (
            <PhoneField
              key={field.name}
              name={field.name}
              placeholder={field.placeholder}
              className="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 text-gray-900"
              countryCodeStyle={'text-gray-900'}
              phoneInputContainerStyle="placeholder:text-gray-400"
              onFocus={pauseContactModal}
              value={formData[field.name]}
              onChange={changeHandler}
              error={errors[field.name]}
              required
            />
          );
        }

        return (
          <InputField
            key={field.name}
            inputStyle="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 placeholder:text-gray-400"
            type={type}
            label={field?.label}
            name={field.name}
            placeholder={field.placeholder}
            value={formData[field.name]}
            onFocus={pauseContactModal}
            onChange={changeHandler}
            error={errors[field.name]}
            required
          />
        );
      })}

      <Checkbox
        name="termNCondition"
        onChange={changeHandler}
        checked={formData?.termNCondition}
        error={errors?.termNCondition}
        required
      >
        By continuing, you confirm that you have read and agreed to AlmaBetter's{' '}
        <Link href="/pages/terms-of-use">
          <a>
            <span className="cursor-pointer text-red">Terms</span>
          </a>
        </Link>{' '}
        and{' '}
        <Link href="/pages/privacy-policy">
          <a>
            <span className="cursor-pointer text-red">Privacy policy</span>
          </a>
        </Link>
      </Checkbox>

      <Primary type="submit" label={buttonCTA} size={'large'} disabled={isSubmitting} />

      {!!errors?.submit && (
        <p className="ml-1 mt-1 text-center text-xs text-red md:text-sm">{errors?.submit}</p>
      )}
    </form>
  );
}

export default GlobalForm;
