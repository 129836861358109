import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

const BASE_URL = 'https://www.almabetter.com';

const defaults = {
  title: 'AlmaBetter - Fast forward your career in tech',
  description:
    'Launch your career in tech with high impact courses. Earn ₹5 - 25 LPA at leading companies. Pay us after you land your dream job.',
  image: 'https://almablog-media.s3.ap-south-1.amazonaws.com/Alma_Better_Logo_4d9d929fe6.png',
};

const SiteHeader = ({
  title = defaults.title,
  desc = defaults.description,
  url = null,
  image = defaults.image,
  canonicalUrl = null,
  structuredData,
  metaRobots = null,
  customSchema = [],
  authorName = null,
  publishedAt = null,
  updatedAt = null,
  readingTime = null,
}) => {
  const router = useRouter();
  const path = router.asPath;
  const articlePath = canonicalUrl || BASE_URL + (url || path);
  const urlObj = new URL(articlePath);
  const canonical = urlObj.origin + urlObj.pathname;
  const structuredSchema = useMemo(() => {
    const tSchema = [];
    if (customSchema && Array.isArray(customSchema)) {
      tSchema.push(...customSchema);
    }
    if (structuredData && typeof structuredData === 'object') {
      if (Array.isArray(structuredData)) {
        tSchema.push(...structuredData);
      } else {
        tSchema.push(structuredData);
      }
    }
    return tSchema.filter((o) => !!o);
  }, [customSchema, structuredData]);
  return (
    <Head>
      <link rel="icon" href="/favicon.ico" />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={desc} />
      {/* <meta
          name='robots'
          content={`${isIndexing ? "index, follow " : "noindex, nofollow"}`}
        /> */}
      <meta name="url" content={canonical} />
      {authorName && <meta name="author" content={authorName} />}
      {publishedAt && <meta property="article:published_time" content={publishedAt} />}
      {updatedAt && <meta property="article:modified_time" content={updatedAt} />}
      {metaRobots && <meta name="robots" content={metaRobots} />}
      <link rel="canonical" href={canonical} />
      <meta property="og:type" content={authorName ? 'article' : 'website'} />
      <meta property="og:site_name" content="AlmaBetter" />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@AlmaBetter" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={image} />
      {authorName && (
        <>
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content={authorName} />
        </>
      )}
      {readingTime && (
        <>
          <meta name="twitter:label2" content="Est. reading time" />
          <meta name="twitter:data2" content={`${readingTime} minutes`} />
        </>
      )}

      <script type="application/ld+json">{JSON.stringify(structuredSchema, null, 2)}</script>
    </Head>
  );
};

export default SiteHeader;
