import React, { useState, useEffect } from 'react';
import * as Scroll from 'react-scroll'; // required for smooth scroll
import {
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

const BackToTop = () => {
  const [scrolled, setScrolled] = useState(0);
  const scrollTo = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    // return window.removeEventListener("scroll", listenToScroll);
  }, []);
  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    // const height =
    //   document.documentElement.scrollHeight -
    //   document.documentElement.clientHeight;
    // const scrolled = winScroll / height;
    setScrolled(Math.round(winScroll));
  };
  return (
    <>
      {scrolled > 1000 ? (
        <div
          className="-z-1 fixed top-[120px] right-1 float-right cursor-pointer lg:right-4"
          onClick={() => scrollTo()}
        >
          <img src="/assets/icons/top.png" alt="" className="h-[36px] w-[36px]" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BackToTop;
