import Image from 'next/image';
import academicCap from '@assets/degrees/academic-cap.png';
import { MdLocalPhone } from 'react-icons/md';

const FormHeader = ({ pgCourse = false }) => {
  return (
    <div className="grid gap-y-[4px]">
      {pgCourse ? (
        <p className="font-satoshi-bold text-[20px] leading-[30px] text-black">Request Callback</p>
      ) : (
        <p className="font-satoshi-medium text-[20px] leading-[30px] text-black">
          Speak to Our <strong>Admission's Team</strong> and clear your doubts!
        </p>
      )}

      <p className="font-satoshi-medium text-[12px] leading-[18px] text-gray-400">
        Fill out the form below!
      </p>
    </div>
  );
};

export default FormHeader;
