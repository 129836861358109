import React, { useContext, useEffect } from 'react';
import { sourceType } from './ContactUs';
import BytesWidget from '../bytes/BytesWidget';
import { EventContext } from 'pages/events';
import { useStore } from '@globalStore/storeProvider';
import { oneClickButtonRef } from '@components/local/contact-us-forms/RequestCallBack';

export const eventPopupList = () => [
  sourceType.stripBanner,
  sourceType.pageBanner,
  sourceType.eventLisitingPage,
  sourceType.eventLandingPage,
];

function OneClickPopup() {
  const { widgetSource, eventData } = useStore();

  const eventContext = useContext(EventContext);

  const PopList = eventPopupList();

  useEffect(() => {
    let timer = null;

    if (eventContext) {
      timer = setTimeout(() => {
        oneClickButtonRef?.current?.click();
      }, 100);
    }

    return () => timer && clearTimeout(timer);
  }, [eventContext]);

  if (!PopList?.includes(widgetSource) || (!eventContext && !eventData)) return <></>;

  sessionStorage.setItem('eventWidgetVisited', true);

  return (
    <div className={`fixed inset-0 z-50 ${eventContext ? 'hidden' : ''}`}>
      <BytesWidget openByStripBanner eventsData={eventContext || eventData} />
    </div>
  );
}

export default OneClickPopup;
