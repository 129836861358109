import cn from 'classnames';
import { Fragment } from 'react';

export function RadioButtonGroup({
  className,
  options,
  name,
  value,
  onChange,
  groupLabel,
  groupLabelStyle,
  radioButtonStyle,
  radioButtonLabelStyle,
  required,
}) {
  return (
    <div className={cn(className)}>
      <label className={cn('my-2 text-xs font-thin capitalize text-gray-dark', groupLabelStyle)}>
        {groupLabel}
      </label>
      <div className="flex items-center gap-2">
        {options.map((optionObj) => (
          <label key={optionObj.value}>
            <input
              type="radio"
              className={cn(radioButtonStyle)}
              name={name}
              value={optionObj.value}
              checked={value === optionObj.value}
              onChange={onChange}
              required={required}
            />
            <span
              className={cn('ml-1 mr-4 text-sm font-thin text-gray-dark', radioButtonLabelStyle)}
            >
              {optionObj.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
}
