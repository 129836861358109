import GlobalBrandingHeader from '../global-form/GlobalBrandingHeader';
import GlobalFormHeader from '../global-form/GlobalFormHeader';
import ApplicationForm from './index';
import cn from 'classnames';

function ApplicationFormWithHeader({
  formTitle,
  formDesc,
  closeModal,
  buttonCTA,
  SuccessScreen,
  formContainerStyle,
  formLayoutStyle,
  container,
  source,
  ...props
}) {
  return (
    <div
      className={cn(
        'relative flex flex-col gap-y-[16px] rounded-b-lg bg-white p-[24px] pr-0',
        container
      )}
    >
      <ApplicationForm
        FormHeader={
          <GlobalFormHeader formTitle={formTitle} formDesc={formDesc} closeModal={closeModal} />
        }
        SuccessScreen={SuccessScreen}
        source={source}
        buttonCTA={buttonCTA}
        formContainerStyle={cn('pr-[24px]', formContainerStyle)}
        formLayout={formLayoutStyle}
        {...props}
      />
    </div>
  );
}

function ApplicationFormWithBranding({
  formTitle,
  formDesc,
  closeModal,
  SuccessScreen,
  formContainerStyle,
  formLayoutStyle,
  container,
  source,
  buttonCTA,
  ...props
}) {
  return (
    <GlobalBrandingHeader closeModal={closeModal}>
      <ApplicationFormWithHeader
        {...{
          formTitle,
          formDesc,
          buttonCTA,
          SuccessScreen,
          formContainerStyle,
          formLayoutStyle,
          container,
          source,
          ...props,
        }}
      />
    </GlobalBrandingHeader>
  );
}

export { ApplicationFormWithHeader, ApplicationFormWithBranding };
