import { createContext } from 'react';
import axios from 'axios';
import EventList from '../../components/local/events/EventList';
import BackToTop from '../../components/shared/button/BackToTop';
import SiteHeader from '../../components/shared/SiteHeader';
import { getStrapiURL } from '../../lib/api';

export const EventContext = createContext();

const apiBaseUrl = getStrapiURL();
export default function Events(props) {
  return (
    <>
      <SiteHeader
        title={'Learn About AlmaBetter’s Upcoming and Past Events.'}
        desc={
          "Keep up with our latest events. Get the scoop on upcoming and past events. From seminars to networking mixers, there's something for everyone at AlmaBetter"
        }
        url={'/events'}
        image={props.seo?.image?.data?.attributes?.url}
      />
      <div className="container-default-1209px" id="modal">
        <BackToTop />

        <EventList {...props} />
      </div>
    </>
  );
}

export async function getStaticProps() {
  try {
    const { data } = await axios.get(
      `${apiBaseUrl}/api/master-classes?populate=*,instructor.DisplayPicture,instructor.CompanyImage,eventImage&pagination[page]=1&pagination[pageSize]=100&sort[0]=eventDate:desc&filters[$and][1][slug][$ne]=how-can-an-iit-certification-propel-your-tech-career`
    );
    const {
      data: { data: workshopsData = [] },
    } = await axios.get(`${apiBaseUrl}/api/workshops?fields[0]=slug`);

    const workshops = workshopsData?.reduce((prev, curr) => {
      return { ...prev, [curr?.attributes?.slug]: '' };
    }, {});

    const { data: seoData } = await axios.get(`${apiBaseUrl}/api/global?populate=seo.image`);
    return {
      props: { events: data, seo: seoData.data.attributes.seo, workshops },
      revalidate: 1800,
    };
  } catch (error) {
    console.error('event-listing [Error]', error);
    return { events: error };
  }
}
