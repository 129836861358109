export function getUtmQueryString(query) {
  let queryString = '';

  if (query?.utm_source) {
    for (const property in query) {
      queryString += `&${property}=${query[property]}`;
    }
  }
  return queryString;
}
