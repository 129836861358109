import React from 'react';
import { FaCircleNotch } from 'react-icons/fa';
// import './Loader.css'; // Import your CSS styles

const Loader = () => {
  return (
    <div className="flex h-36 w-full items-center justify-center">
      <FaCircleNotch className="h-24 animate-spin text-5xl text-red" />
    </div>
  );
};

export default Loader;
