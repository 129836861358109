import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const SIZE_CONST = {
  '2xl': 'text-[18px] leading-7 py-[16px] px-[24px]',
  xl: 'text-[16px] leading-6 py-[12px] px-[20px]',
  large: 'text-[16px] leading-6 py-[10px] px-[18px]',
  medium: 'text-[14px] leading-5 py-[10px] px-[16px]',
  small: 'text-[14px] leading-5 py-[8px] px-[12px]',
};

const ICON_BTN_SIZE = {
  large: 'p-[12px]',
  medium: 'p-[10px]',
  small: 'p-[8px]',
};

const HIERARCHY_SIZE = {
  large: 'text-[16px] leading-6',
  medium: 'text-[14px] leading-[20px]',
  small: 'text-[14px] leading-[20px]',
};

export const Button = ({
  primary,
  backgroundColor,
  size,
  label,
  labelColor,
  capitalCase,
  borderColor,
  linkCTA,
  href,
  customStyle,
  disabled,
  iconCTA,
  hierarchyBtn,
  ...props
}) => {
  const mode = primary
    ? 'bg-red text-white border border-red hover:bg-red-dark hover:border-red-dark disabled:bg-red-light disabled:border-red-light'
    : `border-gray-3 border ${
        props.selected ? 'bg-gray-50 text-gray-9' : 'bg-white text-gray-7'
      } hover:bg-gray-50 hover:text-gray-9 disabled:bg-white disabled:border-gray-2 disabled:text-gray-3`;

  const hierarchyBtnMode = primary
    ? 'text-red hover:text-torch-red-800 disabled:text-gray-3'
    : 'text-gray-11 hover:text-gray-7 disabled:text-gray-3';

  const textCase = capitalCase ? 'uppercase' : 'normal-case';

  let buttonStyle = '';

  if (backgroundColor) {
    buttonStyle += `bg-${backgroundColor} `;
  }

  if (borderColor) {
    buttonStyle += `border-${borderColor} `;
  }

  if (labelColor) {
    buttonStyle += `text-${labelColor} `;
  }

  const { children } = props;

  if (linkCTA && !disabled && !hierarchyBtn) {
    return (
      <Link href={href || ''} as={href || ''} passHref>
        <a
          className={`flex items-center justify-center gap-x-[8px] rounded-lg text-center font-satoshi-bold font-satoshi-bold font-bold  shadow-sm ${mode} ${buttonStyle} ${SIZE_CONST[size]} ${textCase} ${customStyle}`}
          {...props}
        >
          {label}

          {children}
        </a>
      </Link>
    );
  }

  if (iconCTA && !hierarchyBtn) {
    return (
      <button
        type="button"
        className={`rounded-lg shadow-sm ${mode} ${buttonStyle} ${ICON_BTN_SIZE[size]} ${customStyle}`}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    );
  }

  if (hierarchyBtn) {
    if (linkCTA && !disabled) {
      return (
        <Link href={href || ''} as={href || ''} passHref>
          <a
            className={`flex items-center gap-x-[8px] text-center font-satoshi-bold ${hierarchyBtnMode} ${HIERARCHY_SIZE[size]} ${buttonStyle} ${textCase} ${customStyle}`}
            {...props}
          >
            {label}

            {children}
          </a>
        </Link>
      );
    }

    return (
      <button
        type="button"
        className={`flex items-center gap-x-[8px] font-satoshi-bold ${hierarchyBtnMode} ${HIERARCHY_SIZE[size]} ${buttonStyle} ${textCase} ${customStyle}`}
        disabled={disabled}
        {...props}
      >
        {label}

        {children}
      </button>
    );
  }

  return (
    <button
      type="button"
      className={`rounded-lg font-satoshi-bold font-satoshi-bold font-bold  shadow-sm ${mode} ${buttonStyle} ${SIZE_CONST[size]} ${textCase} ${customStyle}`}
      disabled={disabled}
      {...props}
    >
      {label}

      {children}
    </button>
  );
};

Button.propTypes = {
  primary: PropTypes.bool,

  backgroundColor: PropTypes.string,

  size: PropTypes.oneOf(['small', 'medium', 'large', 'xl', '2xl']),

  label: PropTypes.string.isRequired,

  labelColor: PropTypes.string,

  capitalCase: PropTypes.bool,

  borderColor: PropTypes.string,

  linkCTA: PropTypes.bool,

  href: PropTypes.string,

  customStyle: PropTypes.string,

  disabled: PropTypes.bool,

  iconCTA: PropTypes.bool,

  hierarchyBtn: PropTypes.bool,

  onClick: PropTypes.func,
};

Button.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'medium',
  labelColor: null,
  capitalCase: false,
  borderColor: null,
  linkCTA: false,
  href: '',
  customStyle: '',
  disabled: false,
  iconCTA: false,
  hierarchyBtn: false,
  onClick: undefined,
};
