import React, { useEffect } from 'react';
import InputField from '../../../stories/InputField';
import { BsArrowRepeat } from 'react-icons/bs';
import { BiError } from 'react-icons/bi';
import FormHeader from '../degree/degreeLeadForm/FormHeader';
import { useStore } from '@globalStore/storeProvider';
import { sourceType } from '@components/shared/widgets/ContactUs';

const OtpForm = ({
  formData,
  formError,
  formSubmitStatus,
  resendDelay,
  setresendDelay,
  handleCallbackForm,
  handleRequestOtp,
  handleVerifyOtp,
  isLeadExists,
  degree,
  eventPage,
  isEventRegistration,
  isEventWidgetPopup,
  OTPButtonRef,
  pgCourse,
}) => {
  const { widgetSource } = useStore();

  useEffect(() => {
    let interval;
    const timer = () => {
      interval = setTimeout(() => {
        if (resendDelay <= 0) {
          clearInterval(interval);
          //   setallowResend(true);
        } else {
          setresendDelay((prev) => prev - 1);
        }
      }, 1000);
    };
    resendDelay && timer();
    return () => {
      clearInterval(interval);
    };
  }, [resendDelay]);

  const restartDelay = async (e) => {
    e.preventDefault();
    if (!!resendDelay) return;
    setresendDelay(30);
    handleRequestOtp(e, true); // (e, resend true)
  };

  if (isLeadExists == null) return null;

  return (
    <>
      {degree ? <FormHeader pgCourse={pgCourse} /> : null}
      <form
        className={`relative border border-gray-6 p-6 ${
          eventPage && !isEventWidgetPopup
            ? 'mt-4'
            : isEventWidgetPopup
            ? 'slim-scroll h-fit px-4 py-1 md:max-h-[48vh]'
            : 'bg-white'
        } [&>*]:mb-2`}
        onChange={handleCallbackForm}
        onSubmit={handleVerifyOtp}
      >
        {!degree ? (
          <label htmlFor="mobile" className="my-2 text-xs capitalize text-gray-dark">
            edit phone number*
          </label>
        ) : null}
        <div className="relative w-full">
          <input
            type="tel"
            name="phone"
            id="phone"
            required
            placeholder="Mobile Number"
            value={formData?.phone}
            className="spin-button-none block w-full rounded-sm border border-gray-border p-3 pl-9 text-xs outline-none placeholder:capitalize md:text-sm"
          />
          <p className="absolute top-1/2 left-5 -translate-x-1/2 -translate-y-1/2 text-xs md:text-sm">
            +91
          </p>
          <button
            onClick={(e) => restartDelay(e)}
            // disabled={phoneNumber.length < 10}
            className={`absolute top-1/2 right-0 -translate-y-1/2 md:right-1 ${
              !!resendDelay && 'pointer-events-none bg-opacity-30 text-gray-4'
            } h-10
              w-5/12 cursor-pointer whitespace-nowrap rounded-sm  bg-red py-2 px-2 text-center text-xs font-extrabold capitalize text-white disabled:bg-[#B4B4B4] md:w-4/12 md:text-sm`}
          >
            {!!resendDelay ? (
              <p className="whitespace-nowrap text-xs font-extrabold">
                <span>{('0' + resendDelay).slice(-2)}sec</span>
              </p>
            ) : (
              <span>Get OTP</span>
            )}
          </button>
        </div>
        {formError?.phone && <p className="mt-1 text-sm text-red md:text-sm">{formError?.phone}</p>}
        <InputField
          className="mx-auto"
          inputStyle="spin-button-none h-12"
          inputmode="numeric"
          type="tel"
          label={degree ? undefined : 'Enter OTP'}
          name="otp"
          placeholder="Enter Your OTP"
          required
        />
        <div className="flex items-center justify-end text-right capitalize">
          {formSubmitStatus?.error && (
            <div className="mt-1 mr-auto flex items-center justify-center text-sm font-extrabold text-red md:text-sm">
              <BiError className="mr-1 text-lg" />
              {formSubmitStatus?.error}
            </div>
          )}
          {formError?.otp && <p className="m-1 mr-auto text-sm text-red">{formError?.otp}</p>}
        </div>
        <button
          type="submit"
          disabled={formSubmitStatus?.loading}
          className={`mx-auto mt-6 flex h-11 w-full items-center justify-center rounded-sm bg-red p-2 font-satoshi-medium font-medium  capitalize text-white disabled:opacity-80`}
          ref={OTPButtonRef}
        >
          {formSubmitStatus?.loading ? (
            <BsArrowRepeat className="animate-spin text-2xl" />
          ) : isLeadExists ? (
            widgetSource === sourceType.brouchure ? (
              'Download Brochure'
            ) : eventPage || isEventRegistration ? (
              'Confirm Registration'
            ) : (
              'Request callback'
            )
          ) : (
            'Submit OTP'
          )}
        </button>
      </form>
    </>
  );
};

export default OtpForm;
