import { navbarRef } from '@components/shared/header/HeaderComponent';
import useMediaQuery from '@utils/hooks/useMediaQuery';
import { useEffect, useLayoutEffect, useRef, useState, useCallback, createRef } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { Primary } from 'stories/Button.stories';

export const NavigationDivRef = createRef();

const NavigationMenu = ({ exploreCTA, degree, isWeb3Course = false }) => {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [menuList, setMenuList] = useState([]);
  const menuBar = useRef();
  const activeMenuItem = useRef();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const isMobile = useMediaQuery('(max-width: 767.98px)');

  const scrollMenuBar = useCallback(() => {
    const remainingSpace = menuBar.current.clientWidth - activeMenuItem.current.offsetWidth;
    const spaceLeftAndRight = remainingSpace / 2;
    menuBar.current.scrollLeft = activeMenuItem.current.offsetLeft - spaceLeftAndRight;
  }, []);

  const handleScroll = useCallback(() => {
    const navbar = navbarRef.current;

    const YAxis = degree && isMobile ? 2350 : isMobile ? 1900 : degree ? 1700 : 1600;

    if (window.scrollY > YAxis && !isMobile) {
      navbar.classList.remove('sticky');
      navbar.classList.add('static');
    } else if (window.scrollY > YAxis && isMobile) {
      navbar.classList.remove('sticky');
      navbar.classList.add('static');
    } else {
      navbar.classList.remove('static');
      navbar.classList.add('sticky');
    }

    const sections = document.querySelectorAll('.module');
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (pageYOffset >= sectionTop - 400) {
        const current = section.getAttribute('id');
        if (selectedMenu !== current) {
          setSelectedMenu(current);
          scrollMenuBar();
        }
      }
    });
  }, [selectedMenu, scrollMenuBar, isMobile]);

  const handleMenuChange = useCallback((menu) => {
    const element = document.getElementById(menu);
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.pageYOffset - 20,
    });
  }, []);

  useLayoutEffect(() => {
    const menuItems = [];
    document.querySelectorAll('.module').forEach((section) => {
      menuItems.push(section.getAttribute('id'));
    });
    setSelectedMenu(menuItems[0]);
    setMenuList(menuItems.filter(Boolean));
  }, []);

  useEffect(() => {
    if (!window) return;
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuBarScroll = useCallback(() => {
    const scrollLeft = menuBar.current.scrollLeft;
    const containerWidth = menuBar.current.clientWidth;
    const contentWidth = menuBar.current.scrollWidth;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft + containerWidth < contentWidth);
  }, []);

  return (
    <div
      ref={NavigationDivRef}
      className={`sticky z-30 grid grid-cols-12 items-center gap-x-[8px] border-b-2 border-solid border-gray-100 bg-white px-[10px] pt-[22px] text-center capitalize lg:px-[16px]`}
    >
      <img
        src="/assets/almabetter/logo.svg"
        className="col-span-1 hidden h-8 lg:block"
        alt="almabetter"
      />

      {showLeftArrow && (
        <div
          style={{
            background: 'linear-gradient(270deg, rgba(201,80,22,0) 0%, rgba(255,255,255,1) 39%)',
          }}
          onClick={() =>
            menuBar.current.scrollTo({
              left: menuBar.current.scrollLeft - 200,
              behavior: 'smooth',
            })
          }
          className="col-span-1 grid h-full w-10 place-items-center bg-white text-gray-8/40 lg:hidden"
        >
          <IoIosArrowBack size={28} />
        </div>
      )}

      <div
        ref={menuBar}
        onScroll={handleMenuBarScroll}
        className="no-scrollbar relative col-span-10 flex items-center justify-evenly gap-x-[16px] overflow-x-auto text-base md:col-span-9"
      >
        {menuList.map((menu) => (
          <div
            key={menu}
            name={menu}
            ref={selectedMenu === menu ? activeMenuItem : null}
            onClick={() => handleMenuChange(menu)}
            className={`menubar-items cursor-pointer font-satoshi-bold  ${
              selectedMenu === menu
                ? 'activeMenu  border-red text-red'
                : 'border-transparent  text-gray-500'
            } w-auto whitespace-nowrap border-b-4 border-solid py-[8px] px-[4px] font-satoshi-medium md:p-[8px] lg:text-[16px]`}
          >
            {menu}
          </div>
        ))}
      </div>

      {showRightArrow && (
        <div
          style={{
            background: 'linear-gradient(90deg, rgba(201,80,22,0) 0%, rgba(255,255,255,1) 39%)',
          }}
          onClick={() =>
            menuBar.current.scrollTo({
              left: menuBar.current.scrollLeft + 200,
              behavior: 'smooth',
            })
          }
          className="col-span-1 grid h-full w-10 place-items-center bg-white text-gray-8/40 lg:hidden"
        >
          <IoIosArrowForward size={28} />
        </div>
      )}

      <Primary
        linkCTA
        href={exploreCTA}
        label={degree ? 'Start Application' : 'Explore Program'}
        customStyle={'mb-1 hidden md:flex col-span-2 justify-self-end'}
        disabled={isWeb3Course}
      />
    </div>
  );
};

export default NavigationMenu;
