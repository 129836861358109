import { getImgUrl } from '@utils/functions/getImgUrl';
import { DateTimeFormatter } from '@utils/utils';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { PrimaryBadge } from 'stories/Badge.stories';
import { Primary } from 'stories/Button.stories';
import cn from 'classnames';
import EventSuccessScreen from './EventSuccessScreen';
import { IoCloseOutline } from 'react-icons/io5';

function EventInfoCard({
  eventData,
  oneClickUI,
  formData,
  closeModal,
  source,
  showSuccess = false,
}) {
  const {
    instructor: {
      data: [{ attributes: instructorData }],
    },
  } = eventData;

  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const screenSwitchHandler = () => {
    if (!formData) return;

    setShowSuccessScreen(true);
  };

  useEffect(() => {
    if (showSuccess) {
      setShowSuccessScreen(showSuccess);
    }
  }, [showSuccess]);

  return (
    <>
      {showSuccessScreen ? (
        <div className="rounded-lg bg-white p-[24px]">
          <EventSuccessScreen
            {...formData}
            eventData={eventData}
            isOneClickUI={oneClickUI}
            source={source}
          />
        </div>
      ) : (
        <div
          className={cn(
            'eventCard--dark flex cursor-auto flex-col items-center gap-y-[16px] p-[16px] md:gap-y-[24px] md:p-[24px]',
            {
              '!rounded-none !rounded-t-lg md:!rounded-l-lg md:!rounded-r-none': !oneClickUI,
              '!rounded-lg': oneClickUI,
            }
          )}
        >
          <div className="flex flex-col items-start gap-y-[8px]">
            <div className={cn({ 'hidden md:block': !oneClickUI })}>
              <PrimaryBadge
                label={eventData?.EventType || eventData?.eventType}
                backgroundColor={'transparent'}
              />
            </div>

            <p className="font-satoshi-bold text-[20px] leading-7 text-white">{eventData.title}</p>
            <p
              className={cn('font-satoshi-medium text-[16px] leading-6 text-gray-2', {
                'hidden md:block': !oneClickUI,
              })}
            >
              {eventData.description}
            </p>
          </div>

          <div className="flex w-full justify-between gap-[16px] md:flex-col md:justify-start md:pl-[8px]">
            <div className="flex items-start gap-x-[8px]">
              <IoIosCheckmarkCircleOutline className="text-red" size={18} />
              <p className="font-satoshi-medium text-[12px] leading-4 text-white">
                {DateTimeFormatter(
                  `${eventData?.eventDate}T${eventData?.startTime}`,
                  'ddd, DD MMM YYYY'
                )}
              </p>
            </div>
            <div className="flex items-start gap-x-[8px]">
              <IoIosCheckmarkCircleOutline className="text-red" size={18} />
              <p className="font-satoshi-medium text-[12px] leading-4 text-white">
                {DateTimeFormatter(`${eventData?.eventDate}T${eventData?.startTime}`, 'h A')} -{' '}
                {DateTimeFormatter(`${eventData?.eventDate}T${eventData?.endTime}`, 'h A')}
              </p>
            </div>
            <div className="flex items-start gap-x-[8px]">
              <IoIosCheckmarkCircleOutline className="text-red" size={18} />
              <p className="font-satoshi-medium text-[12px] leading-4 text-white">
                Online Live Session
              </p>
            </div>
          </div>

          <div className="flex items-center gap-x-[16px]">
            <Image
              src={getImgUrl(instructorData.DisplayPicture)}
              width={64}
              height={64}
              objectFit="cover"
              className="overflow-hidden rounded-full bg-white shadow-md"
            />

            <div className="flex flex-col items-center gap-y-1 text-center">
              <p className="border-b border-red font-satoshi-medium text-[16px] leading-6 text-gray-2">
                ft. {instructorData.Name}
              </p>
              <p className="font-satoshi-regular text-[14px] leading-5 text-gray-2">
                {instructorData.Designation?.trim()}, Almabetter
              </p>
            </div>
          </div>

          <p className="font-satoshi-regular text-[14px] leading-5 text-green-cyan">
            Attend live MasterClass to get flat 15% OFF on our courses.
          </p>

          {oneClickUI && (
            <Primary
              label={'Register with 1-Click'}
              customStyle={'w-full'}
              onClick={screenSwitchHandler}
              type="button"
            />
          )}
        </div>
      )}

      {closeModal && (
        <div
          onClick={closeModal}
          className={cn(
            'absolute -right-2 -top-3 h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-[#DCDCDC]',
            { flex: oneClickUI, 'flex md:hidden': !oneClickUI }
          )}
        >
          <IoCloseOutline size={22} className="text-gray-navigation" />
        </div>
      )}
    </>
  );
}

export default EventInfoCard;
