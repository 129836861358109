import dayjs from 'dayjs';

export default function readingTime(text) {
  const wordsPerMinute = 225;
  const words = text?.trim()?.split(/\s+/).length;
  const time = Math.ceil(words / wordsPerMinute);
  return time;
}

export function dateFormat(date) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function eventDateTimeFormat(date, time) {
  return new Date(date + 'T' + time);
}
