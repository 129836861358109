import Link from 'next/link';
import Image from 'next/image';
const Banners = ({
  img,
  title,
  desc,
  link,
  buttonText,
  bgColor = 'bg-red/5',
  textAlign = 'text-left',
  DownloadPlacementReport,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center lg:flex-row lg:justify-evenly ${bgColor}  mt-4 rounded-md border border-yellow-1 p-4 lg:my-4`}
    >
      {img && <Image src={img} alt="" height={700} width={800} loading="eager" />}
      <div className={`flex flex-col lg:${textAlign} text-center lg:ml-8`}>
        <h1 className="mt-4 font-satoshi-bold">{title}</h1>
        <p className="text-body mt-4 font-satoshi-medium">{desc}</p>
        {buttonText && (
          <div className="flex items-center justify-center lg:justify-start">
            <Link href={link} passHref>
              <a>
                <button className="mt-8 w-fit rounded-[10px] border border-red bg-red px-10 py-2 font-satoshi-bold text-white">
                  {buttonText}
                </button>
              </a>
            </Link>
          </div>
        )}
        {DownloadPlacementReport && <DownloadPlacementReport />}
      </div>
    </div>
  );
};

export default Banners;
