import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { WA_LINK } from '@lib/constants';

function WhatsAppBanner() {
  return (
    <section className="mx-auto mb-20 grid w-[87%] gap-8 overflow-hidden rounded-lg bg-gradient-to-r from-[#2F2F2F] to-[#000000] text-white md:grid-cols-2">
      <div className="flex flex-col items-center justify-center gap-y-7 p-8 md:items-start md:p-11">
        <h6 className="text-center font-satoshi-bold text-[22px] leading-[150%] md:text-left md:text-[28px]">
          Learning is better with <br />
          AlmaBetter Community
        </h6>
        <p className="text-center font-satoshi-medium text-[16px] leading-[150%] md:text-[18px] lg:text-left">
          Join a community of serious & passionate tech folks, students, mentors and coaches to
          accelerate your career.
        </p>
        <a href={WA_LINK} target="_blank">
          <button className="flex items-center justify-center gap-x-4 rounded bg-red p-3 font-satoshi-bold !normal-case text-white focus:outline-none">
            <FaWhatsapp size={30} />
            Join Whatsapp Community
          </button>
        </a>
      </div>
      <img src={'/assets/courses/banner/whatsapp-banner.png'} alt="whatsapp" className="-mt-6" />
    </section>
  );
}

export default WhatsAppBanner;
