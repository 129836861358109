import { useMemo, useState, memo, useEffect } from 'react';
import { LeadOnBoarding, SendOTP, VerifyOTP } from '../../../lib/api';
import InputField from '../../../stories/InputField';
import SelectField from '../../../stories/SelectField';
import { BiError } from 'react-icons/bi';
import { BsArrowRepeat } from 'react-icons/bs';
import { useRouter } from 'next/router';
import CourseInterestCards from './CourseInterestCards';
import {
  loginToAnalyticsSvc,
  setAttributesInAnalytics,
  trackEvent,
} from '@lib/analytics/analytics.service';

const upcoming5YearsList = (currentYear) => {
  const yearsList = [];

  for (let i = 0; i <= 5; i++) {
    yearsList.push(currentYear + i);
  }

  return yearsList;
};

export const userTypeOptions = {
  student: {
    label: 'When will you graduate',
    keyName: 'graduationYear',
    options: upcoming5YearsList(new Date().getFullYear()),
  },
  'not-working': {
    label: 'Graduation Year',
    keyName: 'graduationYear',
    options: ['Graduated in 2022', 'Graduated in 2019-2021', 'Graduated before 2019'],
  },
  working: {
    label: 'Years of experience',
    keyName: 'yearsOfExperience',
    options: ['0-2 yrs', '2-4 yrs', '4 or more yrs'],
  },
};

const CallbackForm = ({ courseInterest = '', redirectUrl }) => {
  const [showOtpForm, setshowOtpForm] = useState(false);
  const [callBackForm, setCallbackForm] = useState({ courseInterest });
  const [userType, setuserType] = useState(null);
  const [otpStatus, setotpStatus] = useState({ loading: false, error: null });
  const [resendDelay, setresendDelay] = useState(0);
  const [formError, setFormError] = useState(null);
  const [refId, setRefId] = useState('');

  const router = useRouter();
  const QueryParams = router.asPath.split('?')?.[1];
  const { utm_source, utm_medium, utm_campaign, utm_content, utm_term } = router.query;

  const { gclid, fbclid } = router.query;

  const handleCallbackForm = (e) => {
    let { name, value } = e.target;
    if (name === 'otp') value = value.slice(0, 6);
    else if (name === 'phone') value = value.slice(0, 10);
    setCallbackForm({ ...callBackForm, [name]: value });
  };

  const handleRequestOtp = async (e) => {
    e.preventDefault();
    if (validate()) {
      setAttributesInAnalytics(callBackForm?.name, callBackForm?.email, callBackForm?.phone);
      const { name, currentStatus, phone, otp, email, ...rest } = callBackForm;
      window?.snaptr?.('init', '20dfad1d-7f8b-45be-ac1d-d2137c8ed7a6', {
        user_email: email,
      });
      const payload = {
        [userTypeOptions[userType].keyName]: currentStatus,
        phone: `+91${phone}`,
        firstName: name.split(' ')[0],
        lastName: name.split(' ')[1] || '',
        source: 'Landing page',
        email,
        ...rest,
      };
      trackEvent('landing_page_form_submit', {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
      });
      try {
        setotpStatus({ error: null, loading: true });
        const payload = {
          phone: `+91${callBackForm?.phone}`,
        };

        const response = await SendOTP(payload);
        if (response.message === 'OTP Sent') {
          setRefId(response?.refId);
          setotpStatus({ error: null, loading: false });
          setshowOtpForm(true);
          setresendDelay(30);
        } else {
          setotpStatus({ loading: false, error: 'Failed Sending OTP' });
        }
      } catch (error) {
        setotpStatus({ loading: false, error: 'Failed Sending OTP' });
        console.error(error);
      }
    }
  };

  // AFTER OTP FUNCTION & SIDEFFECTS
  useEffect(() => {
    let interval;
    const timer = () => {
      interval = setTimeout(() => {
        if (resendDelay <= 0) {
          clearInterval(interval);
          setallowResend(true);
        } else {
          setresendDelay((prev) => prev - 1);
        }
      }, 1000);
    };
    resendDelay && timer();
    return () => {
      clearInterval(interval);
    };
  }, [resendDelay]);

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        setotpStatus({ loading: true, error: null });
        const payload = {
          otp: callBackForm?.otp,
          refId,
        };
        const response = await VerifyOTP(payload);
        if (response.message === 'verification success') {
          await onBoardingSubmitApi();
          setotpStatus({ loading: false, error: null });
        } else {
          setotpStatus({ loading: false, error: 'Invalid OTP Entered!' });
        }
      } catch (error) {
        console.error(error);
        setotpStatus({ loading: false, error: 'Failed To Verify OTP!' });
      }
    }
  };

  const onBoardingSubmitApi = async () => {
    const { name, currentStatus, phone, otp, email, ...rest } = callBackForm;
    const payload = {
      [userTypeOptions[userType].keyName]: currentStatus,
      phone: `+91${phone}`,
      firstName: name.split(' ')[0],
      lastName: name.split(' ')[1] || '',
      source: 'Landing page',
      email,
      gclid: gclid ?? undefined,
      fbclid: fbclid ?? undefined,
      ...rest,
    };
    window?.gtag?.('event', 'conversion', {
      send_to: 'AW-595701718/Zn11CL3fgvkCENbfhpwC',
      transaction_id: '',
    });
    window?.gtag?.('event', 'conversion', {
      send_to: 'AW-16512768566/PmX8CIfhvaAZELa088E9',
      transaction_id: '',
    });
    window?.snaptr?.('track', 'SIGN_UP');
    window?.qp?.('track', 'CompleteRegistration');
    window?.fbq?.('track', 'CompleteRegistration');
    try {
      const leadData = await LeadOnBoarding(payload);
      if (leadData?.onboardingInfo?.userId) {
        loginToAnalyticsSvc(leadData?.onboardingInfo?.userId);
      }
      if (!redirectUrl) {
        router.replace('/');
      } else {
        if (!QueryParams) {
          router.replace(redirectUrl);
        } else {
          router.replace(
            redirectUrl.includes('?')
              ? `${redirectUrl}&EmailAddress=${email}&${QueryParams}`
              : `${redirectUrl}?${QueryParams}&EmailAddress=${email}`
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const restartDelay = async (e) => {
    e.preventDefault();
    if (!!resendDelay) return;
    setresendDelay(30);
    handleRequestOtp(e);
  };

  const validate = () => {
    const { email, phone, otp } = callBackForm;
    const error = {};
    if (/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email) === false) {
      error.email = 'Enter valid email address.';
    }
    if (phone.length < 10) {
      error.phone = 'Phone number must be valid and 10 digits.';
    }
    if (otp && otp.length < 6) {
      error.otp = 'OTP must be valid and 6 digits.';
    }
    if (Object.keys(error).length) {
      setFormError({ ...error });
      return false;
    }
    setFormError(null);
    return true;
  };

  if (!callBackForm?.courseInterest) {
    return <CourseInterestCards setCallbackForm={setCallbackForm} />;
  }

  return (
    <div className="flex h-full flex-col items-start">
      <section className="flex h-fit w-full flex-col rounded-lg bg-blue-5 px-5 pt-5 pb-2 text-left [&>*]:mb-2">
        {showOtpForm ? (
          <>
            <div className="mx-auto flex w-full flex-col gap-y-8 md:w-11/12">
              <form className="[&>*]:mb-2" onChange={handleCallbackForm} onSubmit={handleVerifyOtp}>
                <label htmlFor="mobile" className="my-2 text-xs capitalize text-gray-dark">
                  edit phone number*
                </label>
                <div className="relative w-full">
                  <input
                    type="number"
                    name="phone"
                    inputMode="numeric"
                    id="phone"
                    required
                    placeholder="Mobile Number"
                    value={callBackForm?.phone}
                    className="spin-button-none block w-full rounded-sm border border-gray-border p-3 pl-9 text-sm outline-none placeholder:text-sm placeholder:capitalize"
                  />
                  <p className="absolute top-1/2 left-5 -translate-x-1/2 -translate-y-1/2 text-sm">
                    +91
                  </p>
                  <button
                    onClick={(e) => restartDelay(e)}
                    disabled={callBackForm?.phone?.length < 10}
                    className={`absolute top-1/2 right-1 -translate-y-1/2 ${
                      !!resendDelay && 'pointer-events-none bg-opacity-30 text-gray-4'
                    } h-10
              w-5/12 cursor-pointer whitespace-nowrap rounded-sm  bg-red py-2 px-2 text-center text-xs font-extrabold capitalize text-white disabled:bg-[#B4B4B4] md:w-4/12 md:text-sm`}
                  >
                    {!!resendDelay ? (
                      <p className="whitespace-nowrap text-xs font-extrabold">
                        <span>{('0' + resendDelay).slice(-2)}sec</span>
                      </p>
                    ) : (
                      <span>Get OTP</span>
                    )}
                  </button>
                </div>
                {formError?.phone && (
                  <p className="text-xs text-red md:text-sm">{formError?.phone}</p>
                )}

                {/* </div> */}

                <InputField
                  className="mx-auto"
                  inputStyle="h-12"
                  inputMode="numeric"
                  type="number"
                  maxLength="6"
                  label="Enter OTP"
                  name="otp"
                  placeholder="Enter Your OTP"
                  value={callBackForm?.otp}
                  required
                />
                <div className="flex items-center justify-end text-right capitalize">
                  {otpStatus?.error && (
                    <div className="mr-auto flex items-center justify-center text-xs font-extrabold text-red md:text-sm">
                      <BiError className="mr-1 text-lg" />
                      {otpStatus?.error}
                    </div>
                  )}
                  {formError?.otp && (
                    <p className="mr-auto text-xs text-red md:text-sm">{formError?.otp}</p>
                  )}
                </div>

                <button
                  type="submit"
                  disabled={otpStatus?.loading}
                  className={`mx-auto mt-6 flex h-11 w-full items-center justify-center rounded-sm bg-red p-2 font-satoshi-medium font-medium  capitalize text-white disabled:cursor-not-allowed disabled:opacity-70`}
                >
                  {otpStatus?.loading ? (
                    <BsArrowRepeat size={25} className="animate-spin text-2xl" />
                  ) : (
                    'Request a callback'
                  )}
                </button>
              </form>
            </div>
          </>
        ) : (
          <div className="mx-auto w-11/12 py-2">
            <p className="whitespace-nowrap font-satoshi-bold text-base font-bold ">
              Request a callback 👋
            </p>
            <form onChange={handleCallbackForm} className="[&>*]:mb-2" onSubmit={handleRequestOtp}>
              <InputField
                label="name"
                type="text"
                name="name"
                required
                placeholder="enter full name"
              />
              <InputField
                label="email"
                type="email"
                name="email"
                required
                placeholder="enter your email"
              />
              {formError?.email && (
                <p className="text-xs text-red md:text-sm">{formError?.email}</p>
              )}
              <label htmlFor="mobile" className="my-2 text-xs capitalize text-gray-dark">
                phone number *
              </label>
              <div className="flex items-start">
                <div>
                  <input
                    value="+91"
                    disabled
                    className={`mr-4 block w-14 rounded-sm border border-gray-border p-3 text-gray-dark outline-none placeholder:capitalize sm:text-sm`}
                  />
                </div>
                <InputField
                  type="number"
                  name="phone"
                  id="phone"
                  required
                  placeholder="Mobile Number"
                  className="w-full"
                />
              </div>
              {formError?.phone && (
                <p className="text-xs text-red md:text-sm">{formError?.phone}</p>
              )}
              <SelectField
                onChange={({ target }) => setuserType(target.value)}
                name="userType"
                label="working Status"
                required
              >
                <option selected disabled hidden></option>
                <option value="working">Working</option>
                <option value="not-working">Non-Working</option>
                <option value="student">Student</option>
              </SelectField>
              {userType && (
                <>
                  <SelectField
                    name="currentStatus"
                    label={userTypeOptions?.[userType].label}
                    required
                  >
                    {userTypeOptions[userType].options.map((option, index) => (
                      <>
                        {!index && <option key={userType} selected disabled hidden></option>}
                        <option key={option} value={option}>
                          {option}
                        </option>
                      </>
                    ))}
                  </SelectField>
                </>
              )}
              <SelectField name="background" label="Education Stream" required>
                <option selected disabled hidden></option>
                <option value="engineering">Engineering background</option>
                <option value="non-engineering">Non Engineering background</option>
              </SelectField>
              {otpStatus?.error && (
                <div className="mt-2 ml-2 flex items-center text-xs font-extrabold text-red md:text-sm ">
                  <BiError className="mr-1 text-lg" />
                  {otpStatus?.error}
                </div>
              )}
              <button
                type="submit"
                disabled={otpStatus?.loading}
                className={`mt-4 flex h-11 w-full items-center justify-center rounded-sm bg-red px-4 py-1 font-satoshi-medium font-medium  capitalize text-white disabled:opacity-80`}
              >
                {otpStatus?.loading ? (
                  <BsArrowRepeat size={25} className="animate-spin text-sm" />
                ) : (
                  'Request a callback'
                )}
              </button>
            </form>
          </div>
        )}
      </section>
    </div>
  );
};

export default memo(CallbackForm);
