import Image from 'next/image';
import React from 'react';

import woolfLogo from '@assets/degrees/woolf_logo.webp';
import iitLogo from '@assets/new-iit-short-logo-black.png';
import { IoCloseOutline } from 'react-icons/io5';

// bg-gradient-to-r from-[#333333]	to-[#2A2A2A]

function GlobalBrandingHeader({ children, closeModal }) {
  return (
    <div className="relative">
      <div className="flex w-full flex-wrap items-center justify-center gap-[16px] rounded-t-lg bg-gradient-to-r from-[#F95864] via-[#F00037] to-[#333333] p-[16px] shadow-md">
        <p className="font-satoshi-bold text-[22px] leading-[28px] text-white">
          In Partnership with
        </p>
        <div className="flex items-center gap-x-[8px]">
          <Image
            src={iitLogo}
            width={140}
            height={40}
            objectFit="contain"
            className="overflow-hidden rounded bg-white"
          />
          {/* <p className="font-satoshi-medium text-[16px] leading-6 text-white">X</p> */}
          <Image
            src={woolfLogo}
            width={140}
            height={40}
            objectFit="contain"
            className="overflow-hidden rounded bg-white"
          />
        </div>
      </div>

      {closeModal && (
        <div
          onClick={closeModal}
          className="absolute -right-2 -top-3 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-[#DCDCDC]"
        >
          <IoCloseOutline size={22} className="text-gray-navigation" />
        </div>
      )}

      {children}
    </div>
  );
}

export default GlobalBrandingHeader;
