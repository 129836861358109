import { useState, useEffect, useContext } from 'react';
import RequestCallBack from '@local/contact-us-forms/RequestCallBack';
import { useDispatch, useStore } from '../../../globalStore/storeProvider';
import { MdLocalPhone } from 'react-icons/md';
import { useRouter } from 'next/router';
import { getUtmQueryString } from '@lib/getUtmQueryString';
import { COURSE_TYPE, COURSE_TYPE_SLUG_MAPPING, WA_LINK } from '@lib/constants';
import { checkifUGCourse } from '@utils/helper';
import { EventContext } from 'pages/events';
import { IoLogoWhatsapp } from 'react-icons/io';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { eventPopupList } from './OneClickPopup';
import { NewRCBFormWidget } from '../forms/NewCallBackForm';
import { DownloadPlacementReportModal, ReportDownloadForm } from '../forms/ReportDownloadForm';
import { MasterClassEventFormModal } from '../forms/MasterClassEventForm';

// whatsapp_widget
export const sourceType = {
  callback: 'request_call_back_widget',
  whatsapp: 'whatsapp_widget',
  payUpFront: 'pay_upfront_website',
  popup: 'Lead_capture_popup',
  brouchure: 'download_brochure',
  eventRegistration: 'event_registration_popup',
  eventLandingPage: 'event_landing_page',
  eventLisitingPage: 'event_lisiting_page',
  pageBanner: 'page_banner',
  stripBanner: 'strip_banner',
  eventPopup: 'event_popup',
  placement_statistics: 'request_callback_placements_statistics',
  placement_report: 'download_placement_report',
  placement_report_popup: 'download_placement_report_popup',
  one_click: 'one_click_registration',
  lcp2: 'LCP 2',
};

const courseInterestMapping = {
  [COURSE_TYPE_SLUG_MAPPING[COURSE_TYPE.WEB3]]: 'web3',
  [COURSE_TYPE_SLUG_MAPPING[COURSE_TYPE.DATA_SCIENCE]]: 'data science',
  [COURSE_TYPE_SLUG_MAPPING[COURSE_TYPE.MASTER_DATA_SCIENCE]]: 'ms-in-data-science-and-analytics',
  [COURSE_TYPE_SLUG_MAPPING[COURSE_TYPE.MASTER_COMPUTER_SCIENCE]]:
    'ms-in-computer-science-and-engineering',
};

const ContactUs = ({ eventPage = false, isBytesPage = false, embed = false }) => {
  const { widgetSource, courseInterest, eventData, utmRefCode } = useStore();

  let changePositionOfContactLogo = false;

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { query } = router;
  const queryString = getUtmQueryString(query);
  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const initialCourseInterest = () => {
    if (courseInterest) return courseInterest;
    if ('courseSlug' in query) return courseInterestMapping[query.courseSlug];
    if ('degreeSlug' in query) return courseInterestMapping[query.degreeSlug];
    return null;
  };

  if (router.pathname?.startsWith('/jobs')) {
    changePositionOfContactLogo = true;
  }

  const defaultCourseInterest = initialCourseInterest();
  const isWeb3 = defaultCourseInterest === 'web3';
  const isDataScience = defaultCourseInterest === 'data science';
  const exploreCTALink = isWeb3
    ? 'https://grow.almabetter.com/auth/signup?softDevInterest=true&redirectTo=https://grow.almabetter.com/web-dev/home'
    : isDataScience
    ? 'https://grow.almabetter.com/auth/signup?dataScienceInterest=true&redirectTo=https://grow.almabetter.com/data-science/home'
    : 'https://grow.almabetter.com/auth/signup?ms-in-data-science-and-analytics=true&redirectTo=https://grow.almabetter.com/masters-ds/home';

  const eventContext = useContext(EventContext);
  const isEventPopup = widgetSource === sourceType.eventRegistration;

  const PopList = eventPopupList();
  const isEventPopupRequest = PopList?.includes(widgetSource);

  const closeWidget = () => {
    dispatch({
      type: 'SET_WIDGET_SOURCE',
      payload: null,
    });
  };

  useEffect(() => {
    const isWhatsAppEnabled = window?.sessionStorage.getItem('whatsapp_enabled');

    if (isWhatsAppEnabled) {
      setShowWhatsApp(true);
    }

    const timerId = setTimeout(() => {
      setShowWhatsApp(true);
      window?.sessionStorage.setItem('whatsapp_enabled', true);
    }, 17000);

    return () => clearTimeout(timerId);
  }, [router]);

  const [whatsAppUrl, setWhatsAppUrl] = useState(
    `${WA_LINK}?text=Hey AlmaBetter Team! I am interested in registering for your courses. Thanks`
  );

  useEffect(() => {
    if (!utmRefCode) return null;

    const [baseUrl, text] = new URLSearchParams(whatsAppUrl).entries().next()?.value;

    setWhatsAppUrl(`${baseUrl}=${text} %23refId=${utmRefCode}`);
  }, [utmRefCode]);

  if (embed) {
    return (
      <RequestCallBack
        setIsOpen={setIsOpen}
        closeWidget={closeWidget}
        widgetSource={sourceType.lcp2}
        courseInterest={defaultCourseInterest}
        embed
      />
    );
  }

  if (eventPage) {
    return (
      <RequestCallBack
        setIsOpen={setIsOpen}
        closeWidget={closeWidget}
        widgetSource={isEventPopupRequest ? widgetSource : sourceType.eventLandingPage}
        courseInterest={defaultCourseInterest}
        eventPage
        eventData={eventContext || eventData}
      />
    );
  }

  if (
    [sourceType.callback, sourceType.popup, sourceType.placement_statistics].includes(widgetSource)
  ) {
    const asModal = widgetSource === sourceType.popup;

    return <NewRCBFormWidget closeWidget={closeWidget} asModal={asModal} source={widgetSource} />;
  }

  if (
    [sourceType.brouchure, sourceType.placement_report, sourceType.placement_report_popup].includes(
      widgetSource
    )
  ) {
    const isReportModal = widgetSource === sourceType.placement_report_popup;

    if (isReportModal) {
      return <DownloadPlacementReportModal closeModal={closeWidget} />;
    }

    return (
      <ReportDownloadForm closeModal={closeWidget} source={widgetSource} isWebDevProgram={isWeb3} />
    );
  }

  if (
    [
      sourceType.eventLisitingPage,
      sourceType.eventPopup,
      sourceType.stripBanner,
      sourceType.pageBanner,
      sourceType.eventLandingPage,
    ].includes(widgetSource)
  ) {
    return (
      <MasterClassEventFormModal
        closeModal={closeWidget}
        source={widgetSource}
        eventData={eventData || eventContext}
      />
    );
  }

  if ((isEventPopup && !eventContext && !eventData) || isEventPopupRequest) return null;

  return (
    <section
      className={`fixed ${
        changePositionOfContactLogo ? 'bottom-32 md:bottom-5' : 'bottom-5'
      }  right-5 z-[111] lg:right-8`}
    >
      <section className="relative">
        {!isBytesPage && (
          <div
            className={`child:items-center child:whitespace-nowrap child:font-extrabold [&>*:not(:last-child)]:mb-2`}
          >
            <>
              {showWhatsApp && (
                <a
                  href={whatsAppUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="ml-auto flex h-14 w-14 animate-popOut items-center justify-center rounded-full border !border-gray-dark/10 bg-white shadow-[0_4px_8px_rgba(135,135,135,0.35)]"
                >
                  {' '}
                  <IoLogoWhatsapp size={35} className="text-[#3FC351]" />
                </a>
              )}

              <div className="flex items-center justify-between gap-4">
                {(query?.courseSlug || query?.degreeSlug) && (
                  <a
                    href={queryString ? `${exploreCTALink}?${queryString}` : exploreCTALink}
                    target="_blank"
                    className="w-9/12 md:hidden"
                  >
                    <button className="btn-red flex h-12 w-full items-center justify-center rounded-md border-2 border-gray-dark/10 bg-white p-2 px-6 text-center text-xs  text-red shadow-md shadow-red/10 md:text-sm">
                      {query?.degreeSlug ? 'Start Application' : 'Explore Program'}
                    </button>
                  </a>
                )}
                <button
                  onClick={() => {
                    dispatch({
                      type: 'SET_WIDGET_SOURCE',
                      payload: sourceType.callback,
                    });
                  }}
                  className="ml-auto flex h-14 w-14 animate-popOut items-center justify-center rounded-full border !border-gray-dark/10 bg-white shadow-[0_4px_8px_rgba(135,135,135,0.35)]"
                >
                  <MdLocalPhone className="h-8 w-8 text-red" />
                </button>
              </div>
            </>
          </div>
        )}
        {widgetSource && (
          <RequestCallBack
            setIsOpen={setIsOpen}
            closeWidget={closeWidget}
            widgetSource={widgetSource}
            courseInterest={defaultCourseInterest}
            eventData={eventContext || eventData}
          />
        )}
      </section>
    </section>
  );
};

export default ContactUs;
