import { useMemo, useState, useLayoutEffect, useEffect, createRef } from 'react';
import InputField from '../../../stories/InputField';
import SelectField from '../../../stories/SelectField';
import { AiOutlineClose } from 'react-icons/ai';
import { BsArrowRepeat } from 'react-icons/bs';
import { BiError } from 'react-icons/bi';
import Image from 'next/image';
import { LeadExistsCheck, LeadOnBoarding, SendOTP, VerifyOTP } from '@lib/api';
import CallbackSuccess from './CallbackSuccess';
import OtpForm from './OtpForm';
import OnBoardingForm from './OnBoardingForm';
import { userTypeOptions } from '../landing-page/CallbackForm';
import { useRouter } from 'next/router';
import { FcDownload } from 'react-icons/fc';
import Link from 'next/link';
import { sourceType } from '@components/shared/widgets/ContactUs';
import cn from 'classnames';
import { useRef } from 'react';
import moment from 'moment';
import { extractLineInDoubleCurlyBraces, genRegisterRandomNumber } from '@utils/functions/throttle';
import { eventDateTimeFormat } from '@utils/functions/readingTime';
import useMediaQuery from '@utils/hooks/useMediaQuery';
import woodStyleClockIcon from '@assets/bytes/clock-front-color.png';
import BytesWidget from '@components/shared/bytes/BytesWidget';
import { RCB_OPTION_LIST } from '@lib/constants';
import { emailRegex } from '../../../config';
import { loginToAnalyticsSvc, setAttributesInAnalytics } from '@lib/analytics/analytics.service';

export const oneClickButtonRef = createRef();

const RequestCallBack = ({
  widgetSource,
  setIsOpen,
  closeWidget,
  courseInterest = null,
  eventPage = false,
  eventData,
  embed = false,
}) => {
  const localFormData = localStorage.getItem('rcb_form_data');
  const SourceType = sessionStorage.getItem('sourceType');
  const { query, pathname } = useRouter();
  const [formData, setformData] = useState({
    courseInterest: courseInterest,
    ...(!!localFormData && JSON.parse(localFormData)),
    source: SourceType || widgetSource,
  });
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(null);

  const yearOptions = [];
  for (let year = currentYear - 15; year <= currentYear + 5; year++) {
    yearOptions.push(year);
  }
  const [showOtpForm, setshowOtpForm] = useState(null);
  const [userType, setuserType] = useState(null);
  const userId = useRef();
  const [formSubmitStatus, setformSubmitStatus] = useState({
    loading: false,
    error: null,
    otpVerified: false,
    successful: false,
    eventConfirmation: false,
  });
  const [resendDelay, setresendDelay] = useState(0);
  const [formError, setFormError] = useState(null);
  const [isLeadExists, setIsLeadExists] = useState(null);
  const [refId, setRefId] = useState('');
  const isWhatsApp = widgetSource === sourceType.whatsapp;
  const isDownloadBrochure = widgetSource === sourceType.brouchure;
  const [phoneFocus, setPhoneFocus] = useState(false);
  const isEventPopup = widgetSource === sourceType.eventRegistration;
  const isMobile = useMediaQuery('(max-width: 767.98px)');
  const isEventRegistration = widgetSource === sourceType.eventRegistration || eventPage;

  const eventStartTime =
    eventData?.eventStartTime || eventDateTimeFormat(eventData?.eventDate, eventData?.startTime);

  const registeredCount = genRegisterRandomNumber(eventStartTime);

  const router = useRouter();
  const { gclid, fbclid } = router.query;
  const isPlacementReport = widgetSource === sourceType.placement_report;
  const isPlacementReportPopup = widgetSource === sourceType.placement_report_popup;
  const isEventWidgetPopup = [
    sourceType.stripBanner,
    sourceType.eventPopup,
    sourceType.one_click,
    ...(localFormData
      ? [sourceType.pageBanner, sourceType.eventLisitingPage, sourceType.eventLandingPage]
      : []),
  ].includes(formData.source);

  const OTPButtonRef = useRef();
  const requestedToStoreFormData = [sourceType.popup, sourceType.callback].includes(
    formData.source
  );

  // const otpReferenceId = useMemo(() => {
  //   return Math.floor(100000 + Math.random() * 90000);
  // }, []);

  const handleCallbackForm = (e) => {
    let { name, value } = e.target;
    if (name === 'otp') value = value.slice(0, 6);
    let eventName = 'widget_user_attribute_';
    const dataObj = { ...formData, [name]: value };
    if (['userType', 'currentStatus', 'background'].includes(name)) {
      switch (name) {
        case 'userType':
          eventName += 'working_status';
          dataObj?.background && delete dataObj.background;
          break;
        case 'background':
          eventName += 'education_stream';
          dataObj.userType = 'student';
          break;
        case 'currentStatus':
          eventName += userType === 'working' ? 'experience' : 'graduation_year';
          break;
      }
      const eventData = { type: eventName, value, ...dataObj };
      if ('otp' in eventData) {
        delete eventData.otp;
      }
    }
    setformData(dataObj);
  };

  useLayoutEffect(() => {
    if (eventPage) return;

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const trackUserTypeLead = () => {
    const { userType } = formData;
    if (!userType || !userId.current) return;
    if (userType === 'student') return;
    window?.fbq?.('track', 'Lead', {
      content_category: userType === 'working' ? 'Working' : 'NonWorking',
      eventID: userId.current,
    });
    window?.gtag?.('event', 'conversion', {
      send_to: 'AW-595701718/cvF-COGA3Z4YENbfhpwC',
      transaction_id: userId.current,
    });
    window?.gtag?.('event', 'conversion', {
      send_to: 'AW-16512768566/1dVzCJ_qvaAZELa088E9',
      transaction_id: userId.current,
    });
  };

  useEffect(() => {
    const flag = requestedToStoreFormData
      ? formSubmitStatus?.eventConfirmation
      : formSubmitStatus?.successful;

    if (flag) {
      const { otp, ...filledData } = formData;
      if (
        [
          sourceType.eventLandingPage,
          sourceType.eventLisitingPage,
          sourceType.eventRegistration,
          sourceType.pageBanner,
          sourceType.stripBanner,
          sourceType.eventPopup,
          sourceType.one_click,
        ].includes(formData.source)
      ) {
        filledData.source = 'event_registration';
      }

      trackUserTypeLead();
    }
  }, [formSubmitStatus?.successful]);

  const handleRequestOtp = async (e, resend = false) => {
    e.preventDefault();
    if (!validate()) return;
    setformSubmitStatus({ error: null, loading: true });

    setAttributesInAnalytics(formData.name, formData.email, formData.phone);

    const userData = {
      sha256_email_address: formData?.email,
    };

    if (formData?.phone) {
      userData.sha256_phone_number = formData?.phone;
    }

    formData?.source !== sourceType.one_click &&
      window.gtag &&
      window.gtag('set', 'user_data', userData);

    if (!resend) {
      try {
        const { exists } = await LeadExistsCheck(formData.email, widgetSource);

        exists ? setIsLeadExists(true) : setIsLeadExists(false);
        if (isWhatsApp) {
          await onBoardingSubmitApi(true);
          return;
        }
      } catch (error) {
        setformSubmitStatus({ loading: false, error: 'Request Failed, Try Later!' });
        console.error(error);
        return;
      }
    }

    try {
      const payload = {
        phone: `+91${formData?.phone}`,
      };

      const response = await SendOTP(payload);
      if (response.message === 'OTP Sent') {
        await onBoardingSubmitApi();
        setRefId(response?.refId);
        setshowOtpForm(true);
        setresendDelay(30);
        setformSubmitStatus({ error: null, loading: false });

        if (isDownloadBrochure) {
          pathname.includes('degrees')
            ? (payload['widget_type'] = 'download_master_brochure')
            : (payload['widget_type'] = 'download_course_brochure');
        } else if (widgetSource === sourceType.popup) {
          payload['widget_type'] = 'popup';
        } else if (isPlacementReport || isPlacementReportPopup) {
          payload['widget_type'] = 'download_placement_report';
        } else {
          payload['widget_type'] = 'rcb';
        }
      } else {
        setformSubmitStatus({ loading: false, error: 'Failed Sending OTP' });
      }
    } catch (error) {
      setformSubmitStatus({ loading: false, error: 'Failed Sending OTP' });
      console.error(error);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        otp: formData?.otp,
        refId,
      };

      try {
        setformSubmitStatus({ loading: true, error: null });
        const response = await VerifyOTP(payload);

        if (response.message == 'verification success') {
          setformSubmitStatus({
            error: null,
            loading: false,
            otpVerified: true,
            ...(requestedToStoreFormData
              ? { successful: false, eventConfirmation: true }
              : { successful: true }),
          });

          (isEventPopup || eventPage) && (await onBoardingSubmitApi(false, true));

          setshowOtpForm(false);

          sessionStorage.removeItem('subPlanType');

          localStorage.setItem('rcb_form_data', JSON.stringify(formData));

          widgetSource !== sourceType.eventRegistration &&
            requestedToStoreFormData &&
            setformData((prev) => ({
              ...prev,
              source: sourceType.one_click,
            }));
        } else {
          setformSubmitStatus({
            loading: false,
            error: 'Invalid OTP Entered!',
            successful: false,
          });
        }
      } catch (error) {
        console.error(error);
        setformSubmitStatus({
          loading: false,
          error: 'Failed To Verify OTP!',
          successful: false,
        });
      }
    }
  };

  const onBoardingSubmitApi = async (isWhatsappCallback = false, isEventVerification = false) => {
    const { name, currentStatus, phone, otp, ...rest } = formData;
    let payload = null;

    if (isEventVerification) {
      const eventDateTimeISO = moment(
        `${eventData.eventDate}T${eventData.startTime}`
      ).toISOString();
      const registerLink = eventData?.registerLink || eventData?.registerCTALink;
      const eventId = registerLink?.match(/\d+/g)?.[0];
      const eventType = registerLink?.split('=')[1];

      const source =
        formData?.source === sourceType.one_click
          ? sourceType.one_click
          : eventData?.source || sourceType.eventLandingPage;

      payload = {
        email: formData?.email,
        source,
        courseInterest: formData?.courseInterest,
        eventRegistration: {
          eventId,
          eventType,
          eventName: eventData?.title,
          eventScheduledAt: eventDateTimeISO,
        },
        // gclid: gclid ?? undefined,
        // fbclid: fbclid ?? undefined,
      };
    } else {
      const subPlanType = sessionStorage.getItem('subPlanType');
      subPlanType && (formData.sub_plan_interest = subPlanType);
      const fullName = name.split(' ');
      payload = {
        ...(!isWhatsappCallback &&
          userType && { [userTypeOptions[userType].keyName]: currentStatus }),
        phone: `+91${phone}`,
        firstName: fullName.shift(),
        lastName: fullName.join(' ') || '',
        source: widgetSource,
        ...(subPlanType && { subPlanType }),
        ...rest,
      };
    }

    try {
      const leadData = await LeadOnBoarding(payload);

      userId.current = leadData?.onboardingInfo?.userId;
      if (userId.current) {
        loginToAnalyticsSvc(userId.current);

        window?.gtag?.('event', 'conversion', {
          send_to: 'AW-595701718/Zn11CL3fgvkCENbfhpwC',
          transaction_id: userId.current,
        });
        window?.gtag?.('event', 'conversion', {
          send_to: 'AW-16512768566/PmX8CIfhvaAZELa088E9',
          transaction_id: userId.current,
        });
        window?.fbq?.('track', 'CompleteRegistration', { eventID: userId.current });
      }

      if (!isLeadExists && !formData?.userType) {
        return;
      }

      setformSubmitStatus((prev) => {
        return { ...prev, error: null, loading: false, ...(isWhatsApp && { successful: true }) };
      });

      if (isEventVerification) {
        const eventId = payload?.eventRegistration?.eventId;

        const isRegisteredExist = window?.localStorage.getItem('isRegistered');

        const registeredEventIds = (isRegisteredExist && JSON.parse(isRegisteredExist)) || [];

        if (!registeredEventIds?.includes(eventId)) {
          registeredEventIds?.push(eventId);
        }

        window?.localStorage.setItem('isRegistered', JSON.stringify(registeredEventIds));

        sessionStorage.removeItem('sourceType');

        if (!formSubmitStatus.successful) {
          setformSubmitStatus((prev) => {
            return {
              ...prev,
              error: null,
              loading: false,
              eventConfirmation: false,
              successful: true,
            };
          });

          setformData((prev) => ({ ...prev, source: null }));
        }
      }
    } catch (error) {
      console.error(error);
      setformSubmitStatus({
        error: 'Request Failed, Try Later!',
        loading: false,
        successful: false,
      });
      throw error;
    }
  };

  const validate = () => {
    const { email, phone, otp, name } = formData;
    const error = {};
    if (!emailRegex.test(email)) {
      error.email = 'Enter valid email address';
    }
    if (!phone || phone.length !== 10) {
      error.phone = 'Phone number must be 10 digits';
    }
    if (otp && otp.length !== 6) {
      error.otp = 'OTP must be valid and 6 digits';
    }

    if (!name || name.length > 50) {
      error.name = 'Name must be less than 50 characters';
    }

    if (name && !/^[a-z ,.'-]+$/i.test(name)) {
      error.name = 'Name must contain only alphabets';
    }

    if (Object.keys(error).length) {
      setFormError({ ...error });
      return false;
    }
    setFormError(null);
    return true;
  };

  const TrackFromEscape = () => {
    const { otp, ...filledData } = formData;
    let eventName = 'widget_skipped';
    switch (true) {
      case formSubmitStatus.otpVerified:
        filledData.stage = 'Onboarding';
        break;
      case showOtpForm:
        filledData.stage = 'Otp';
        break;
      default:
        filledData.stage = 'Form';
    }
    sessionStorage.removeItem('sourceType');
    sessionStorage.removeItem('subPlanType');
    closeWidget();
  };

  useEffect(() => {
    if (formData?.['otp']?.length === 6) {
      OTPButtonRef?.current?.click();
    }
  }, [formData]);

  const oneClickEventRegistration = async () => {
    try {
      setformSubmitStatus((prev) => {
        return { ...prev, loading: true, successful: false };
      });

      await onBoardingSubmitApi(false, true);
    } catch (error) {
      console.error(error);
      setformSubmitStatus({
        loading: false,
        error: 'Request Failed, Try Later!',
        successful: false,
      });
    }
  };

  const checkEventIsRegistered = (eventData) => {
    const isRegisteredExist = localStorage.getItem('isRegistered');
    const registeredEventId = (isRegisteredExist && JSON.parse(isRegisteredExist)) || [];

    const registerLink = eventData?.registerLink || eventData?.registerCTALink;
    const eventId = registerLink?.match(/\d+/g)?.[0];

    if (registeredEventId?.includes(eventId)) {
      setformSubmitStatus({
        loading: false,
        error: null,
        otpVerified: false,
        successful: true,
        eventConfirmation: false,
      });
    }
  };

  useEffect(() => {
    if (!eventData || requestedToStoreFormData) {
      return;
    }

    checkEventIsRegistered(eventData);
  }, [eventData, requestedToStoreFormData]);

  return (
    <section
      className={
        !eventPage &&
        !embed &&
        cn(
          {
            'md:right-8 md:bottom-[4rem] md:top-auto md:block md:bg-opacity-0 md:backdrop-blur-none child:md:ml-auto':
              widgetSource !== sourceType.popup &&
              widgetSource !== sourceType.eventRegistration &&
              !isPlacementReportPopup,
          },
          {
            'md:!top-[0px]':
              widgetSource === sourceType.popup && isEventPopup && isPlacementReportPopup,
          },
          'fixed inset-0 flex items-center justify-center'
        )
      }
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          TrackFromEscape();
        }}
        className={
          !eventPage &&
          !embed &&
          cn(
            {
              'md:bg-opacity-0 md:backdrop-blur-none':
                widgetSource !== sourceType.popup && !isEventPopup && !isPlacementReportPopup,
            },
            'fixed inset-0 flex cursor-pointer items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm '
          )
        }
      ></div>
      <div
        className={
          !eventPage &&
          !embed &&
          cn(
            `relative w-11/12 animate-fadeRight rounded-md ${
              formData.source == sourceType.one_click ? '' : 'bg-white'
            } child:rounded-md  md:w-8/12  md:shadow-xl md:shadow-gray/10 lg:w-4/12`
          )
        }
      >
        {!eventPage && (formData.source != sourceType.one_click || !eventData) && !embed && (
          <AiOutlineClose
            onClick={() => {
              TrackFromEscape();
              setIsOpen(false);
            }}
            className={`absolute top-4 right-4 z-50 ml-auto h-6 !w-6 cursor-pointer  p-0.5 ${
              isPlacementReportPopup ? 'text-white' : 'text-gray-dark/50'
            }`}
          />
        )}
        <div
          className={isEventPopup || isPlacementReportPopup ? 'slim-scroll h-fit max-h-[80vh]' : ''}
        >
          {isEventPopup || eventPage ? (
            <>
              <div className="flex flex-col p-3 pb-0">
                <div className="mb-1 flex flex-col gap-y-1">
                  {(!isEventWidgetPopup || formSubmitStatus.successful) && (
                    <p className="text-base font-extrabold">
                      {extractLineInDoubleCurlyBraces(eventData?.title)}
                    </p>
                  )}
                  <section
                    className={`flex  flex-row ${
                      isEventWidgetPopup && !!localFormData && !formSubmitStatus.successful
                        ? 'justify-center'
                        : 'justify-between'
                    }  whitespace-nowrap`}
                  >
                    {!isEventWidgetPopup || formSubmitStatus.successful ? (
                      <span className="text-sm">Date : {moment(eventStartTime).format('ll')}</span>
                    ) : (
                      !localFormData && (
                        <p className="font-satoshi-medium text-lg font-semibold">Register Now</p>
                      )
                    )}

                    {!isEventWidgetPopup || formSubmitStatus.successful ? (
                      <p className="mt-2	whitespace-nowrap text-xs md:mt-0 md:text-sm">
                        Hurry Up! Only{' '}
                        <span className="text-red">{registeredCount} seats left.</span>
                      </p>
                    ) : (
                      !formSubmitStatus.successful && (
                        <div className="flex items-center gap-x-2">
                          <Image src={woodStyleClockIcon} layout="fixed" width={34} height={34} />
                          <p className={`${!localFormData ? 'text-xs' : 'text-base'} font-normal`}>
                            Hurry Up! Only{' '}
                            <span className="text-red">{registeredCount} seats left.</span>
                          </p>
                        </div>
                      )
                    )}
                  </section>
                </div>
              </div>
            </>
          ) : isPlacementReportPopup ? (
            <div className="sticky top-0 mb-3 py-4">
              <div className="relative z-10 flex w-full items-center justify-around text-white">
                <div className="flex flex-col items-start justify-center">
                  <h4 className="text-xl font-extrabold">3000+</h4>
                  <p className="text-xs">Placement</p>
                </div>
                <div className="flex flex-col items-start justify-center">
                  <h4 className="text-xl font-extrabold">₹ 33 LPA</h4>
                  <p className="text-xs">Highest Salary</p>
                </div>
                <div className="flex flex-col items-start justify-center">
                  <h4 className="text-xl font-extrabold">92%</h4>
                  <p className="text-xs">Placement Rate</p>
                </div>
              </div>
              <div
                className="absolute inset-0 flex justify-between bg-blend-overlay"
                style={{ background: 'linear-gradient(to bottom, #E40D3F, #5E071B)' }}
              >
                <img src="/assets/icons/travel-fade.png" />
                <img src="/assets/icons/rupee-fade.png" />
                <img src="/assets/icons/fade-text.png" />
              </div>
            </div>
          ) : (
            <></>
          )}
          {(() => {
            switch (true) {
              case formSubmitStatus?.successful:
                return (
                  <CallbackSuccess
                    isWhatsApp={isWhatsApp}
                    isDownloadBrochure={isDownloadBrochure}
                    email={formData?.email}
                    courseInterest={courseInterest}
                    isEventRegistration={
                      (widgetSource == sourceType.eventRegistration || eventPage) &&
                      formData.source !== sourceType.one_click
                    }
                    isEventRegistrationDesktop={
                      eventPage && !isMobile && formData.source !== sourceType.one_click
                    }
                    isPlacementReport={isPlacementReport || isPlacementReportPopup}
                    eventData={
                      eventData && {
                        title: eventData?.shortTitle || eventData?.title,
                        startTime: eventStartTime,
                      }
                    }
                  />
                );
              case formSubmitStatus.eventConfirmation:
                return (
                  <BytesWidget
                    isModalOpenByRCB
                    formSubmitStatus={formSubmitStatus}
                    setFormSubmitStatus={setformSubmitStatus}
                    courseInterest={formData.courseInterest}
                    oneClickHandler={oneClickEventRegistration}
                  />
                );
              case showOtpForm:
                return (
                  <OtpForm
                    {...{
                      formData,
                      formError,
                      formSubmitStatus,
                      resendDelay,
                      setresendDelay,
                      handleCallbackForm,
                      handleRequestOtp,
                      handleVerifyOtp,
                      isLeadExists,
                      eventPage,
                      isEventRegistration:
                        widgetSource == sourceType.eventRegistration || eventPage,
                      isEventWidgetPopup,
                      OTPButtonRef,
                    }}
                  />
                );
              case !!localFormData && isEventWidgetPopup:
                return (
                  <div
                    className={`flex w-full flex-col items-center justify-center gap-y-2 ${
                      formData.source === sourceType.one_click ? 'pt-2 pb-6' : 'pt-4 pb-8'
                    }`}
                  >
                    {formSubmitStatus?.error && (
                      <div className="mt-2 ml-2 flex items-center text-xs font-extrabold text-red md:text-sm ">
                        <BiError className="mr-1 text-lg" />
                        {formSubmitStatus?.error}
                      </div>
                    )}
                    <button
                      ref={oneClickButtonRef}
                      onClick={oneClickEventRegistration}
                      className={`flex w-11/12 items-center justify-center rounded-md bg-red px-4 py-2 font-satoshi-medium text-lg font-semibold normal-case text-white disabled:opacity-80`}
                    >
                      {formSubmitStatus?.loading ? (
                        <BsArrowRepeat className="animate-spin text-2xl" />
                      ) : (
                        'Register with 1-Click'
                      )}
                    </button>
                  </div>
                );
              case showOtpForm === null:
                return (
                  <form
                    onChange={handleCallbackForm}
                    className={
                      isEventPopup || eventPage || isPlacementReportPopup
                        ? `px-4 pb-2 [&>*]:mb-0.5 ${
                            isEventWidgetPopup ? 'slim-scroll h-fit md:max-h-[40vh]' : ''
                          }`
                        : 'slim-scroll h-fit max-h-[80vh] border border-gray-6 bg-white p-4 [&>*]:mb-2'
                    }
                    onSubmit={handleRequestOtp}
                  >
                    {!isEventWidgetPopup && !embed && (
                      <p className="flex items-center whitespace-nowrap text-base font-extrabold">
                        {isWhatsApp ? (
                          <>
                            <Image
                              alt="whatsapp"
                              src="/assets/icons/whatsapp.svg"
                              height={30}
                              width={30}
                            />
                            <span className="ml-2">Whatsapp Us</span>
                          </>
                        ) : isDownloadBrochure ? (
                          <>
                            Download Brochure <FcDownload className="ml-2" />
                          </>
                        ) : isPlacementReport || isPlacementReportPopup ? (
                          <>Placement Report</>
                        ) : (
                          !isEventPopup && !eventPage && 'Request a callback 👋'
                        )}
                      </p>
                    )}
                    <div
                      className={
                        isEventWidgetPopup || embed
                          ? 'grid grid-cols-2 gap-x-4 gap-y-2'
                          : 'flex flex-col gap-y-0.5'
                      }
                    >
                      <InputField
                        inputStyle="h-10"
                        label="name"
                        type="text"
                        name="name"
                        required
                        placeholder="enter full name"
                      />
                      {formError?.name && <p className="m-1 text-sm text-red">{formError?.name}</p>}
                      <div>
                        <InputField
                          label="email"
                          type="email"
                          name="email"
                          required
                          inputStyle="h-10"
                          placeholder="enter your email"
                        />
                        {formError?.email && (
                          <p className="m-1 text-sm text-red">{formError?.email}</p>
                        )}
                      </div>

                      {!courseInterest && (
                        <SelectField
                          name="courseInterest"
                          label="course Interest"
                          selectStyle="h-10 py-0 pl-2 px-8 md:px-2"
                          defaultValue={formData?.courseInterest}
                          required
                        >
                          <option selected disabled hidden></option>
                          {Object.entries(RCB_OPTION_LIST).map(([key, value]) => {
                            return (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            );
                          })}
                        </SelectField>
                      )}
                      <div>
                        <label htmlFor="mobile" className="my-2 text-xs capitalize text-gray-dark">
                          phone number *
                        </label>
                        <div className="flex items-start">
                          <div>
                            <input
                              value="+91"
                              disabled
                              className={`block h-10 w-12 rounded-sm border border-gray-border p-2 text-gray-dark outline-none placeholder:capitalize sm:text-sm md:p-3 ${
                                isEventWidgetPopup || embed ? 'md:mr-2' : 'mr-2'
                              }`}
                            />
                          </div>
                          <InputField
                            type="number"
                            name="phone"
                            id="phone"
                            required
                            inputStyle="h-10"
                            placeholder="Mobile Number"
                            className="w-full"
                            onFocus={() => setPhoneFocus(true)}
                            onInput={(e) => {
                              if (!phoneFocus) {
                                setPhoneFocus(true);
                              }
                            }}
                          />
                        </div>{' '}
                        {formError?.phone && (
                          <p className="m-1 text-sm text-red">{formError?.phone}</p>
                        )}
                      </div>

                      {phoneFocus && (
                        <>
                          <div className={isEventWidgetPopup || embed ? 'col-span-2' : ''}>
                            <SelectField
                              label="Graduation year"
                              onChange={({ target }) => {
                                setSelectedYear(parseInt(target.value));
                              }}
                              name="graduationYear"
                              selectStyle="h-9 py-0 bg-white"
                              required
                            >
                              <option selected disabled hidden></option>
                              {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </SelectField>
                          </div>
                          {selectedYear && selectedYear <= currentYear && (
                            <div className={isEventWidgetPopup || embed ? 'col-span-2' : ''}>
                              <label
                                htmlFor="mobile"
                                className="my-2 text-xs capitalize text-gray-dark"
                              >
                                Are you currently working?
                              </label>
                              <div
                                onChange={({ target }) => {
                                  setuserType(target.value);
                                  setFormError((prev) => {
                                    return { ...prev, userType: undefined };
                                  });
                                }}
                                className={`mb-2 flex items-center text-gray-dark ${
                                  isEventWidgetPopup || embed ? 'gap-x-8' : 'gap-x-4 text-sm'
                                } `}
                              >
                                <div className="flex items-center gap-x-2">
                                  <input type="radio" name="userType" required value="working" />
                                  <span>Yes</span>
                                </div>
                                <div className="flex items-center gap-x-2">
                                  <input type="radio" name="userType" value="not-working" />
                                  <span>No</span>
                                </div>
                              </div>
                              {formError?.userType && (
                                <p className="m-1 text-sm text-red">{formError?.userType}</p>
                              )}
                            </div>
                          )}
                        </>
                      )}

                      {selectedYear && selectedYear > currentYear && (
                        <div className={isEventWidgetPopup || embed ? 'col-span-2' : ''}>
                          <p className="mt-2 text-[0.8rem] leading-5">
                            Are you currently pursuing an engineering course or degree?
                          </p>
                          <div
                            onChange={({ target }) => {
                              setFormError((prev) => {
                                return { ...prev, userType: undefined };
                              });
                            }}
                            className={`mb-2 flex items-center text-gray-dark ${
                              isEventWidgetPopup || embed ? 'gap-x-8' : 'gap-x-4 text-sm'
                            } `}
                          >
                            <div className="flex items-center gap-x-2">
                              <input type="radio" name="background" required value="engineering" />
                              <span>Yes</span>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <input type="radio" name="background" value="non-engineering" />
                              <span>No</span>
                            </div>
                          </div>
                          {formError?.currentStatus && (
                            <p className="m-1 text-sm text-red">{formError?.currentStatus}</p>
                          )}
                        </div>
                      )}

                      {!isEventWidgetPopup && !embed && (
                        <p className="text-[.8rem] leading-5 text-gray">
                          By continuing, you confirm that you have read and agreed to AlmaBetter’s{' '}
                          <Link href="/terms-of-use" passHref as="/terms-of-use">
                            <span className="cursor-pointer text-red">Terms</span>
                          </Link>{' '}
                          and{' '}
                          <Link href="/privacy-policy" passHref as="/privacy-policy">
                            <span className="cursor-pointer text-red">Privacy policy</span>
                          </Link>
                        </p>
                      )}
                      {formSubmitStatus?.error && (
                        <div className="mt-2 ml-2 flex items-center text-xs font-extrabold text-red md:text-sm ">
                          <BiError className="mr-1 text-lg" />
                          {formSubmitStatus?.error}
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      // sticky bottom-4
                      className={`flex w-full items-center justify-center rounded-sm bg-red px-4 py-2 font-satoshi-medium font-medium  text-white disabled:opacity-80 ${
                        embed ? 'normal-case' : ''
                      } ${isEventWidgetPopup || embed ? 'mt-4' : 'mt-2'}`}
                    >
                      {formSubmitStatus?.loading ? (
                        <BsArrowRepeat className="animate-spin text-2xl" />
                      ) : isWhatsApp ? (
                        'Whatsapp us'
                      ) : isEventRegistration ? (
                        'Register Now'
                      ) : embed ? (
                        'Talk to Admission Team'
                      ) : (
                        'Continue'
                      )}
                    </button>
                  </form>
                );
            }
          })()}
        </div>
      </div>
    </section>
  );
};

export default RequestCallBack;
