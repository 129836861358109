import Image from 'next/image';
import React, { useEffect } from 'react';
import { Primary } from 'stories/Button.stories';
import successGif from '@assets/gifs/success.gif';
import { DateTimeFormatter } from '@utils/utils';
import { eventDateTimeFormat } from '@utils/functions/readingTime';
import { genRegisterRandomNumber } from '@utils/functions/throttle';
import { LeadOnBoarding } from '@lib/api';
import cn from 'classnames';
import { getGrowSignupUrl } from 'config';

function EventSuccessScreen({
  email,
  courseInterest,
  eventData,
  isOneClickUI = false,
  source,
  container,
}) {
  const eventStartTime = eventDateTimeFormat(eventData.eventDate, eventData.startTime);

  const registeredCount = genRegisterRandomNumber(eventStartTime);

  const eventRegistration = async () => {
    const isRegisteredExist = window?.localStorage.getItem('isRegistered');

    const registeredEventIds = isRegisteredExist ? JSON.parse(isRegisteredExist) : [];

    const eventDateTimeISO = eventStartTime.toISOString();

    const registerLink = new URL(eventData?.registerLink || eventData?.registerCTALink);
    const eventId = eventData?.eventId || registerLink.toString()?.match(/\d+/g)?.[0];
    const eventType = registerLink.searchParams.get('eventType');

    if (registeredEventIds.includes(eventId)) return;

    const payload = {
      email,
      source,
      courseInterest,
      eventRegistration: {
        eventId,
        eventType,
        eventName: eventData?.title,
        eventScheduledAt: eventDateTimeISO,
      },
    };

    const leadData = await LeadOnBoarding(payload);

    const userId = leadData?.onboardingInfo?.userId;

    if (userId) {
      window?.gtag?.('event', 'conversion', {
        send_to: 'AW-595701718/Zn11CL3fgvkCENbfhpwC',
        transaction_id: userId,
      });
      window?.gtag?.('event', 'conversion', {
        send_to: 'AW-16512768566/PmX8CIfhvaAZELa088E9',
        transaction_id: userId,
      });
      window?.fbq?.('track', 'CompleteRegistration', { eventID: userId });
    }

    registeredEventIds.push(eventId);

    window?.localStorage.setItem('isRegistered', JSON.stringify(registeredEventIds));
  };

  const clickHandler = () => {
    if (!email) return;

    email.match(/@([^.]+)\./)[1] === 'gmail'
      ? window?.open(`${getGrowSignupUrl()}?source=rcb_success`, '_blank')
      : window?.open(getGrowSignupUrl(), '_blank');
  };

  useEffect(() => {
    eventRegistration();
  }, []);

  return (
    <div className={cn('flex flex-col gap-y-[24px]', container)}>
      {isOneClickUI && (
        <div className="flex flex-col gap-y-[16px]">
          <p className="font-satoshi-medium text-lg font-semibold text-gray-800">
            {eventData.title}
          </p>
          <div className="flex items-center justify-between">
            <p className="font-satoshi-medium text-[14px] leading-5 text-gray-navigation">
              Date: {DateTimeFormatter(eventStartTime, 'MMM DD, YYYY')}
            </p>
            <p className="font-satoshi-medium text-[14px] leading-5 text-gray-navigation">
              Hurry Up! Only{' '}
              <span className="font-satoshi-bold font-bold  text-red">
                {registeredCount} seats left.
              </span>
            </p>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-center gap-y-[4px]">
        <Image src={successGif} alt="registrationSuccess" height={100} width={100} loading="lazy" />

        <p className="font-satoshi-bold text-[24px] leading-[30px]">Thank You :)</p>
      </div>

      <p className="font-satoshi-medium text-[18px] leading-7">
        It's fantastic to connect with you! Join us at our live event to engage and catch up in
        real-time.
      </p>

      <div className="flex flex-col gap-y-[16px] rounded-lg bg-red-light-1 p-[24px]">
        <p className="font-satoshi-medium text-[16px] leading-6">
          <span className="text-">🚀</span> Meanwhile, You can explore our student’s dashboard and
          start learning for free.
        </p>

        <Primary
          label={'Start learning for Free!'}
          type="button"
          size={'large'}
          onClick={clickHandler}
        />
      </div>
    </div>
  );
}

export default EventSuccessScreen;
