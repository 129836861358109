import { useLayoutEffect, useState } from 'react';
import web3 from '@assets/images/web3.svg';
import datascience from '@assets/images/dataScience.svg';
import Image from 'next/image';

const CourseInterestCards = ({ setCallbackForm }) => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const handleInterestChange = () => {
    setCallbackForm({ courseInterest: selectedCourse });
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <section
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black/20 backdrop-blur-sm`}
    >
      <div className="relative flex flex-col justify-between rounded-lg  bg-white p-6 text-left text-lg font-extrabold capitalize md:w-[52%] md:p-8">
        <h5 className="mb-1 text-xl">Welcome to Almabetter</h5>
        <h6 className="text-base text-gray-dark md:mb-2">
          Please choose your course of interest ?
        </h6>
        <section className="my-4 flex flex-wrap justify-center gap-y-3 gap-x-5 md:flex-nowrap md:gap-y-6">
          <div
            onClick={() => setSelectedCourse('data-science')}
            className={`relative ${
              selectedCourse === 'data-science'
                ? 'border-2 border-blue-8'
                : 'border border-blue-3/20'
            } flex h-36  w-full cursor-pointer flex-col items-center justify-center gap-y-2 rounded-lg bg-[#f3f5fe] p-4 py-2 md:h-48`}
          >
            {selectedCourse === 'data-science' && (
              <input checked type="radio" className="absolute top-4 left-5 h-4 w-4" />
            )}
            <Image
              objectFit="contain"
              src={datascience}
              height={80}
              width={90}
              alt="data-science"
            />
            <p className="text-center text-[1rem]  font-extrabold md:text-sm">
              Full Stack Data Science Course
            </p>
          </div>
          <div
            onClick={() => setSelectedCourse('web3')}
            className={`relative ${
              selectedCourse === 'web3' ? 'border-2 border-blue-8' : 'border border-blue-3/20'
            }  flex h-36 w-full cursor-pointer flex-col items-center justify-center rounded-lg bg-[#f3f5fe] py-2 px-2 md:h-48 md:gap-y-2 md:py-4`}
          >
            {selectedCourse === 'web3' && (
              <input checked type="radio" className="absolute top-4 left-5 h-4 w-4" />
            )}
            <Image objectFit="contain" src={web3} height={80} width={90} alt="web3" />
            <p className="px-2 text-center text-[1rem] font-extrabold  md:px-0 md:text-sm">
              Full Stack Development with Web3
            </p>
          </div>
        </section>
        <button
          onClick={handleInterestChange}
          disabled={!selectedCourse}
          className="mx-auto w-60 rounded-sm bg-red py-3 text-base text-white disabled:bg-opacity-70 md:mt-2"
        >
          Continue
        </button>
      </div>
    </section>
  );
};

export default CourseInterestCards;
