import { NEW_COURSE_SLUG } from '@lib/constants';
import { toast } from 'react-toastify';

export const checkifUGCourse = (slug) => {
  return slug === 'ug-diploma-in-computer-science';
};

export const checkIfMasterCourse = (slug) => {
  return slug.includes('masters');
};

export const checkifPGCourse = (data, slug) => {
  const pgCertificationsObj = data.find((item) => item.type === 'PG Certifications');
  if (pgCertificationsObj) {
    return pgCertificationsObj.courses.some((course) => course.slug === slug);
  }
  return false;
};

export const checkCityBasedSlug = (slug) => {
  const shatteredSlug = slug?.split('-');

  let lastIndex = 0;

  shatteredSlug?.forEach((item, index) => {
    if (item === 'in' && index > 1) {
      lastIndex = index;
    }
  });

  const extractedSlug = shatteredSlug?.slice(0, lastIndex).join('-');

  const newSlug = NEW_COURSE_SLUG[extractedSlug] || extractedSlug;

  return {
    isCitySlug: lastIndex > 0 ? true : false,
    slug: newSlug,
  };
};

export const generateSixDigitHexaCode = () => {
  const characters = '0123456789ABCDEFabcdef';

  const timestamp = Date.now();

  const pid = Math.floor(Math.random() * 0xffffff);

  const combinedValue = (timestamp << 16) | (pid & 0xffff);

  let hexCode = combinedValue.toString(16).toUpperCase().slice(-6).split('');

  hexCode = hexCode
    .map((char) => {
      if (characters.indexOf(char) !== -1) {
        return characters.charAt(Math.floor(Math.random() * characters.length));
      }

      return char;
    })
    .join('');

  return hexCode;
};

export const TableGenerator = ({ headings, values }) => {
  return (
    <table className="m-4">
      <thead>
        <tr>
          {headings.map((heading, index) => (
            <th key={index} className=" px-12">
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((value, colIndex) => (
              <td key={colIndex} className="text-center">
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const copyClipBoard = (content) => {
  // Create a temporary textarea element to hold the HTML content
  const tempTextarea = document.createElement('textarea');
  tempTextarea.value = content;

  // Append the textarea to the document
  document.body.appendChild(tempTextarea);

  // Select the text inside the textarea
  tempTextarea.select();
  // tempTextarea.setSelectionRange(0, 99999); // For mobile devices

  // Execute the copy command
  document.execCommand('copy');

  // Remove the temporary textarea from the document
  document.body.removeChild(tempTextarea);

  // Provide some feedback to the user (optional)
  toast.success('Text copied!', {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
};

export const extractHexCode = (str = '') => {
  const hexRegex = /[0-9a-fA-F]+(?:-[0-9a-fA-F]+)*/g;

  const extractedHexCodes = str.match(hexRegex) || [];

  return extractedHexCodes;
};

export function replaceTemplateStrings(template, values) {
  return template.replace(/{{(\w+)}}/g, (match, key) => {
    return values[key] !== undefined ? values[key] : match;
  });
}
