import { useStore } from '@globalStore/storeProvider';
import axios from 'axios';
import { getGrowSignupUrl } from 'config';
import React, { useEffect } from 'react';
import { Primary } from 'stories/Button.stories';
import successGif from '@assets/gifs/success.gif';
import Image from 'next/image';

function ReportDownloadSuccessScreen({ email, isPlacementReport }) {
  const { brochureLink, placementReportLink } = useStore();

  const clickHandler = () => {
    if (!email) return;

    email.match(/@([^.]+)\./)[1] === 'gmail'
      ? window?.open(`${getGrowSignupUrl()}?source=rcb_success`, '_blank')
      : window?.open(getGrowSignupUrl(), '_blank');
  };

  const downloadReport = async () => {
    try {
      const FILE_URL = isPlacementReport ? placementReportLink : brochureLink;

      const response = await axios.get(FILE_URL, {
        responseType: 'blob',
      });

      const blob = response.data;

      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement('a');

      link.href = url;

      link.setAttribute(
        'download',
        (brochureLink || placementReportLink || 'Brouchure.pdf')
          .replace(/.*\//, '')
          .replace(/\+/g, ' ')
      );

      link.setAttribute('target', '_blank');

      document.body.appendChild(link);

      link.dispatchEvent(new MouseEvent('click'));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    downloadReport();
  }, []);

  return (
    <div className="flex flex-col items-center gap-y-[16px]">
      <div className="flex flex-col items-center gap-y-[4px]">
        <Image src={successGif} alt="registrationSuccess" height={100} width={100} loading="lazy" />
        <p className="font-satoshi-bold text-[24px] leading-[30px]">Thank You :)</p>
      </div>

      <p className="font-satoshi-medium text-[18px] leading-7">
        {isPlacementReport
          ? 'Explore our exceptional track record, where our proven success in placements demonstrates our commitment to your career growth.'
          : 'Explore our brochure to discover details about admissions, learning opportunities, and placements.'}
      </p>

      <div className="flex flex-col gap-y-[16px] rounded-lg bg-red-light-1 p-[24px]">
        <p className="font-satoshi-medium text-[16px] leading-6">
          <span className="text-">🚀</span> In the meantime, sign up and take a free tour of our
          program!
        </p>
        <Primary
          label={'Start learning for Free!'}
          onClick={clickHandler}
          type="button"
          size={'large'}
        />
      </div>
    </div>
  );
}

export default ReportDownloadSuccessScreen;
