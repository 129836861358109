import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

export default function MyModal({ slug }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    document.getElementById('modal').classList.remove('modal-open');
  }

  function openModal() {
    setIsOpen(true);
    document.getElementById('modal').classList.add('modal-open');
  }

  return (
    <>
      {/* <div className='inset-0 flex items-center justify-center bg-opacity-75'> */}
      <button
        onClick={openModal}
        className="rounded-[10px] border border-red bg-white px-10 py-2 font-satoshi-medium text-sm font-medium  uppercase text-red hover:bg-opacity-30 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        Share
      </button>
      {/* </div> */}

      <Transition appear show={isOpen}>
        <Dialog
          as="div"
          className="z-99 fade modal fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 100 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-flex w-full max-w-md transform flex-col items-center overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-center font-satoshi-bold text-xl font-bold ">
                  Invite your peers to the event
                </Dialog.Title>
                <div className="my-6 inline-flex items-center gap-4">
                  <FacebookShareButton
                    className="rounded-full border border-red bg-white px-10 py-2 text-red"
                    url={slug}
                  >
                    <FacebookIcon size={38} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    className="rounded-full border border-red bg-white px-10 py-2 text-red"
                    url={slug}
                  >
                    <TwitterIcon size={38} round={true} />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    className="rounded-full border border-red bg-white px-10 py-2 text-red"
                    url={slug}
                  >
                    <LinkedinIcon size={38} round={true} />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    className="rounded-full border border-red bg-white px-10 py-2 text-red"
                    url={slug}
                  >
                    <WhatsappIcon size={38} round={true} />
                  </WhatsappShareButton>
                  {/* <WhatsappShareButton
                    className='px-10 py-2 bg-white border rounded-full border-red text-red'
                    url={`/events`}
                  >
                    <LineIcon size={38} round={true} />
                  </WhatsappShareButton> */}
                </div>
                <button
                  type="button"
                  className="hover:bg-blue-700 focus:bg-blue-700 active:bg-blue-800 rounded-md bg-red px-10 py-2 text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
