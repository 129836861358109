import { COURSES_SLUGS } from '@lib/constants';

const COURSE_MAP = {
  'masters-in-computer-science-data-science-ai':
    'Masters in CS : Data Science and Artificial Intelligence',
  'masters-in-computer-science-software-engineering':
    'Masters in Computer Science : Software Engineering',
  [COURSES_SLUGS.UG_DIPLOMA_COMPUTER_SCIENCE]: 'UG : Diploma In Computer Science',
};

const SKIP_ROUTE = ['master-class', 'workshop'];

const removeHyphens = (input) => {
  return input.replace(/-/g, ' ');
};

export const getPathArray = (pathname) => {
  const asPathWithoutQuery = pathname?.split('?')[0];
  const asPathNestedRoutes = asPathWithoutQuery
    ? asPathWithoutQuery.split('/').filter((path) => (path && !SKIP_ROUTE.includes(path)) || !path)
    : [];

  const pathArray = asPathNestedRoutes?.map((subpath, index) => {
    const href = `/${asPathNestedRoutes?.slice?.(1, index + 1)?.join('/')}`;

    let title = removeHyphens(COURSE_MAP[subpath] || subpath);

    const hashIndex = title.indexOf('#');
    if (hashIndex > -1) {
      title = title.substring(0, hashIndex);
    }

    return { href, text: title == '' ? 'home' : decodeURIComponent(title) };
  });
  return pathArray;
};
