import React, { useEffect } from 'react';
import {
  ApplicationFormWithBranding,
  ApplicationFormWithHeader,
} from '../ApplicationForm/ApplicationFormVariant';
import useMediaQuery from '@utils/hooks/useMediaQuery';
import ReportDownloadSuccessScreen from './ReportDownloadSuccessScreen';
import { sourceType } from '@components/shared/widgets/ContactUs';
import { replaceTemplateStrings } from '@utils/helper';
import cn from 'classnames';
import PlacementReportFormBanner from './PlacementReportFormBanner';

function ReportDownloadForm({ source, closeModal, isWebDevProgram = false }) {
  const FORM_HEADER_MAP = {
    [sourceType.brouchure]: {
      formTitle: 'Download Brochure',
      formDesc: 'Get to know all the details of our {{courseName}} program',
      buttonCTA: 'Download Brochure',
    },

    [sourceType.placement_report]: {
      formTitle: 'Download Placement Report',
      formDesc: 'Go through the statistics of our placed Alumni.',
      buttonCTA: 'Download Placement Report',
    },
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const isMobile = useMediaQuery('(max-width: 767.98px)');

  const { formTitle, formDesc, buttonCTA } = FORM_HEADER_MAP[source];

  return (
    <section
      className={cn(
        'fixed inset-0 z-[1111] flex h-full w-full cursor-pointer bg-black bg-opacity-20 md:p-[24px]',
        {
          'items-end justify-end': !isMobile,
          'items-center justify-center backdrop-blur-sm': isMobile,
        }
      )}
      onClick={closeModal}
    >
      <div
        className={cn({ 'w-2/6': !isMobile, 'px-[16px]': isMobile })}
        onClick={(e) => e.stopPropagation()}
      >
        <ApplicationFormWithBranding
          closeModal={closeModal}
          source={source}
          formTitle={formTitle}
          formDesc={replaceTemplateStrings(formDesc, {
            courseName: isWebDevProgram ? 'Web Development' : 'Data Science',
          })}
          SuccessScreen={ReportDownloadSuccessScreen}
          isPlacementReport={source === sourceType.placement_report}
          buttonCTA={buttonCTA}
        />
      </div>
    </section>
  );
}

function DownloadPlacementReportModal({ closeModal }) {
  const { formTitle, formDesc } = {
    formTitle: 'Download Placement Report',
    formDesc: 'Go through the statistics of our placed Alumni.',
  };

  return (
    <section
      className={
        'fixed inset-0 z-[1111] flex h-full w-full cursor-pointer items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm'
      }
      onClick={closeModal}
    >
      <div className={'px-[16px] md:w-2/6 md:px-0'} onClick={(e) => e.stopPropagation()}>
        <PlacementReportFormBanner>
          <ApplicationFormWithHeader
            formTitle={formTitle}
            formDesc={formDesc}
            closeModal={closeModal}
            source={sourceType.placement_report_popup}
            SuccessScreen={ReportDownloadSuccessScreen}
            isPlacementReport
            buttonCTA={'Download Placement Report'}
          />
        </PlacementReportFormBanner>
      </div>
    </section>
  );
}

export { ReportDownloadForm, DownloadPlacementReportModal };
