import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';

function EventFormHeader({ formTitle, formDesc, closeModal }) {
  return (
    <>
      <div className="grid cursor-auto">
        <p className="font-satoshi-bold text-[20px] leading-[30px] text-black">{formTitle}</p>
        <p className="font-satoshi-regular text-[16px] leading-6 text-gray-navigation">
          {formDesc}
        </p>
      </div>

      {closeModal && (
        <div
          onClick={closeModal}
          className="absolute -right-2 -top-3 hidden h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-[#DCDCDC] md:flex"
        >
          <IoCloseOutline size={22} className="text-gray-navigation" />
        </div>
      )}
    </>
  );
}

export default EventFormHeader;
