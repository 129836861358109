import imageIcon from '@assets/images/imageIcon.svg';
export const getImgUrl = (payload, isHighQuality = false) => {
  const formats = payload?.data?.attributes?.formats || payload?.formats || payload;

  if(isHighQuality) {
    return payload?.data?.attributes?.url
  }

  return (
    formats?.medium?.url ||
    formats?.large?.url ||
    payload?.data?.attributes?.url ||
    formats?.url ||
    payload?.url ||
    imageIcon
  );
};
