import cn from 'classnames';

export function Checkbox({
  className,
  labelStyle,
  error,
  checkboxStyle,
  errorStyle,
  children,
  ...props
}) {
  return (
    <div className={cn(className)}>
      <label className={cn('flex items-start justify-start')}>
        <input className={cn('mt-1', checkboxStyle)} type="checkbox" {...props} />
        <span className={cn('ml-2 !font-satoshi-medium text-xs text-gray-200', labelStyle)}>
          {children}
        </span>
      </label>
      {error && (
        <p className={cn(`ml-1 mt-1 text-xs font-thin text-red md:text-sm`, errorStyle)}>{error}</p>
      )}
    </div>
  );
}
