import moment from 'moment';

export function throttle(callback, limit) {
  var wait = false;
  return function () {
    if (!wait) {
      callback.call();
      wait = true;
      setTimeout(function () {
        wait = false;
      }, limit);
    }
  };
}

export const genRegisterRandomNumber = (startTime) =>
  Math.abs(9 - (7 - moment(startTime).diff(new Date(), 'days')));

const customRankComparator = (a, b) => {
  const rankA = a.rank || a.attributes?.rank || 100;
  const rankB = b.rank || b.attributes?.rank || 100;
  return rankA - rankB;
};

export const customCourseTypeComparator = (dataArray, courseType = null) =>
  courseType
    ? dataArray
        .reduce(
          (prev, curr) => {
            if (curr?.attributes?.courseType === courseType) {
              prev[0].push(curr);
            } else {
              prev[1].push(curr);
            }

            return prev;
          },
          [[], []]
        )
        .map((item) => item.sort(customRankComparator))
        .flat()
    : dataArray.sort(customRankComparator);

export function scrollToElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

export function extractLineInDoubleCurlyBraces(text) {
  const regex = /\{\{([\s\S]*?)\}\}/g;
  let extractedText = text;
  let match;

  while ((match = regex.exec(text))) {
    const extractedContent = match[1].trim();
    extractedText = extractedText?.replace(match[0], extractedContent);
  }

  extractedText = extractedText?.replace(/\s{2,}/g, ' ');

  return extractedText;
}
