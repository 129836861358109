import React, { useEffect, useState } from 'react';
import EventInfoCard from './EventInfoCard';
import cn from 'classnames';
import EventSuccessScreen from './EventSuccessScreen';
import useStorage from '@utils/hooks/useStorage';
import ApplicationForm from '../ApplicationForm';
import EventFormHeader from './EventFormHeader';
import EmbedEventFormHeader from './EmbedEventFormHeader';

function EventFormWithEventCard({
  eventData,
  closeModal,
  source,
  isOneClickRegistration = false,
  localFormData,
  showSuccess,
}) {
  return (
    <div className={cn('relative grid', { 'md:grid-cols-2': !isOneClickRegistration })}>
      <EventInfoCard
        eventData={eventData}
        oneClickUI={isOneClickRegistration}
        formData={localFormData}
        closeModal={closeModal}
        source={source}
        showSuccess={showSuccess}
      />
      {!isOneClickRegistration && (
        <div
          className={
            'relative flex flex-col gap-y-[16px] rounded-b-lg bg-white p-[24px] pr-0 md:!rounded-r-lg md:rounded-b-none'
          }
        >
          <ApplicationForm
            FormHeader={
              <EventFormHeader
                formTitle={'Register for MasterClass Event'}
                formDesc={'Share your details to join our MasterClass event'}
                closeModal={closeModal}
              />
            }
            SuccessScreen={EventSuccessScreen}
            source={source}
            buttonCTA={'Register Now'}
            formContainerStyle={cn('pr-[24px]')}
            eventData={eventData}
          />
        </div>
      )}
    </div>
  );
}

function MasterClassEventFormModal({ eventData, closeModal, source }) {
  const { getItemFromLocal } = useStorage();
  const [showSuccess, setShowSuccess] = useState(false);

  const localFormData = getItemFromLocal('rcb_form_data');

  const isFormAlreadyFilled = !!localFormData;

  const isRegisteredExist = getItemFromLocal('isRegistered');

  const registeredEventId = isRegisteredExist ? JSON.parse(isRegisteredExist) : [];

  useEffect(() => {
    if (registeredEventId.includes(eventData?.eventId)) {
      setShowSuccess(true);
    }
  }, [registeredEventId]);

  if (!eventData?.instructor) return <></>;

  return (
    <section
      className={
        'fixed inset-0 z-[1111] flex h-full w-full cursor-pointer items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm'
      }
      onClick={closeModal}
    >
      <div
        className={cn('px-[16px] md:px-0', {
          'md:w-2/4': !isFormAlreadyFilled,
          'md:w-2/6': isFormAlreadyFilled,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <EventFormWithEventCard
          eventData={eventData}
          closeModal={closeModal}
          source={source}
          isOneClickRegistration={isFormAlreadyFilled}
          localFormData={isFormAlreadyFilled ? JSON.parse(localFormData) : null}
          showSuccess={showSuccess}
        />
      </div>
    </section>
  );
}

function EmbedMasterClassEventForm({
  eventData,
  source,
  formContainerStyle,
  showSuccess,
  localFormData,
}) {
  if (showSuccess) {
    return (
      <div className="rounded-lg p-[24px]">
        <EventSuccessScreen eventData={eventData} source={source} isOneClickUI {...localFormData} />
      </div>
    );
  }

  return (
    <div className={'relative flex flex-col gap-y-[16px] rounded-lg bg-white p-[24px] pr-0'}>
      <ApplicationForm
        FormHeader={<EmbedEventFormHeader eventData={eventData} />}
        SuccessScreen={EventSuccessScreen}
        source={source}
        buttonCTA={'Register Now'}
        formContainerStyle={cn('pr-[24px]', formContainerStyle)}
        eventData={eventData}
      />
    </div>
  );
}

export { MasterClassEventFormModal, EmbedMasterClassEventForm };
