import { getGrowSignupUrl } from 'config';
import Image from 'next/image';
import React from 'react';
import { Primary } from 'stories/Button.stories';
import successGif from '@assets/gifs/success.gif';

function RCBSuccessScreen({ email }) {
  const clickHandler = () => {
    if (!email) return;

    email.match(/@([^.]+)\./)[1] === 'gmail'
      ? window?.open(`${getGrowSignupUrl()}?source=rcb_success`, '_blank')
      : window?.open(getGrowSignupUrl(), '_blank');
  };

  return (
    <div className="flex flex-col items-center gap-y-[16px]">
      <div className="flex flex-col items-center gap-y-[4px]">
        <Image src={successGif} alt="registrationSuccess" height={100} width={100} loading="lazy" />
        <p className="font-satoshi-bold text-[24px] leading-[30px]">Thank You :)</p>
      </div>

      <p className="font-satoshi-medium text-[18px] leading-7">
        We hear you and are actively addressing your request. Our team will be in touch with you
        shortly with updates.
      </p>

      <div className="flex flex-col gap-y-[16px] rounded-lg bg-red-light-1 p-[24px]">
        <p className="font-satoshi-medium text-[16px] leading-6">
          <span className="text-">🚀</span> In the meantime, sign up and take a free tour of our
          program!
        </p>
        <Primary
          label={'Start learning for Free!'}
          onClick={clickHandler}
          type="button"
          size={'large'}
        />
      </div>
    </div>
  );
}

export default RCBSuccessScreen;
