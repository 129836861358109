const useStorage = () => {
  const isBrowser = (() => typeof window !== 'undefined')();

  const getItem = (key, type) => {
    return isBrowser ? sessionStorage.getItem(key) : '';
  };

  const getItemFromLocal = (key, type) => {
    return isBrowser ? localStorage.getItem(key) : '';
  };

  const setItem = (key, value) => {
    if (isBrowser) {
      sessionStorage.setItem(key, value);
      return true;
    }

    return false;
  };

  const removeItem = (key) => {
    if (isBrowser) {
      sessionStorage.removeItem(key);
      return true;
    }
    return false;
  };

  return {
    getItem,
    setItem,
    removeItem,
    getItemFromLocal,
  };
};

export default useStorage;
