import Script from 'next/script';

const ExternalScripts = () => {
  return (
    <>
      <Script id="MSclarity" type="text/javascript" strategy="lazyOnload">
        {`(function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', 'e2anl7sktc');`}
      </Script>

      <script
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '578796149843003');
          fbq('track', 'PageView');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img
          height='1'
          width='1'
          style='display:none'
          src='https://www.facebook.com/tr?id=670018410389466&ev=PageView&noscript=1'
        />`,
        }}
      />
      <Script
        strategy="lazyOnload"
        src="https://www.googletagmanager.com/gtag/js?id=AW-595701718"
      />
      <Script id="ga" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-595701718', { allow_enhanced_conversions: true });
        `}
      </Script>
      <Script
        strategy="lazyOnload"
        src="https://www.googletagmanager.com/gtag/js?id=AW-16512768566"
      />
      <Script id="ga" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16512768566', { allow_enhanced_conversions: true });
        `}
      </Script>

      <Script id="snapchat" strategy="lazyOnload">
        {`
        (function (e, t, n) {
          if (e.snaptr) return;
          var a = (e.snaptr = function () {
            a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
          });
          a.queue = [];
          var s = 'script';
          r = t.createElement(s);
          r.async = !0;
          r.src = n;
          var u = t.getElementsByTagName(s)[0];
          u.parentNode.insertBefore(r, u);
        })(window, document, 'https://sc-static.net/scevent.min.js');
        snaptr('init', '20dfad1d-7f8b-45be-ac1d-d2137c8ed7a6');
        snaptr('track', 'PAGE_VIEW');
        `}
      </Script>

      <Script id="sharechat">
        {`(function (w, d, s, l, i) {
        w['scSdkId'] = i;
        w[l] = w[l] || []
        w.scq = function (eventName, eventType, p) {
		  var props = p || {}
          w[l].push({ eventName: eventName, eventType: eventType, meta: props, eventFireTs: Date.now() })
        };
        w.scq("PAGE_VIEW", "AUTO", {
          pageUrl: w.location.href
        });
        var scr = d.createElement(s);
        scr.type = 'text/javascript';
        scr.async = true;
        scr.src = 'https://sc-events-sdk.sharechat.com/web-sdk.js';
        var x = d.getElementsByTagName(s)[0];
        x.parentNode.insertBefore(scr, x);
      })(window, document, "script", "scLayer", "gzjdW69RrN");`}
      </Script>
      {/* <!-- Quora Pixel Code (JS Helper) --> */}
      <Script id="quora" strategy="lazyOnload">
        {`!function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
        qp('init', '02f751d6c40c478e97198ce10e5b8b46');
        qp('track', 'ViewContent');
        `}
      </Script>
      {/* <Script id="bing" strategy={'lazyOnload'}>
        {`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:“52006952"};o.q=w[u],w[u]=new UET(o),w[u].push(“pageLoad”)},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!==“loaded”&&s!==“complete”||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,“script”,“//bat.bing.com/bat.js”,“uetq”);`}
      </Script> */}
    </>
  );
};

export default ExternalScripts;
