import React, { useEffect, useState } from 'react';
import { fetchEventData } from './BytesWidget';
import { useRouter } from 'next/router';
import { eventDateTimeFormat } from '@utils/functions/readingTime';
import useStorage from '@utils/hooks/useStorage';
import { useDispatch, useStore } from '@globalStore/storeProvider';
import { sourceType } from '../widgets/ContactUs';

function EventPopup() {
  const dispatch = useDispatch();

  const { eventData } = useStore();

  const { query, pathname } = useRouter();

  const { getItemFromLocal } = useStorage();

  const fetchingEventData = async () => {
    const res = await fetchEventData(query?.courseSlug, pathname);

    if (!res) return null;

    const eventLiveTime =
      eventDateTimeFormat(res?.eventDate, res?.startTime)?.getTime() - 30 * 60 * 1000;

    const isRegisteredExist = getItemFromLocal('isRegistered');

    const registeredEventId = (isRegisteredExist && JSON.parse(isRegisteredExist)) || [];

    if (registeredEventId?.includes(res?.eventId) && eventLiveTime > Date.now()) {
      dispatch({
        type: 'SET_EVENT_DATA',
        payload: null,
      });
    } else {
      dispatch({
        type: 'SET_EVENT_DATA',
        payload: res,
      });
    }
  };

  useEffect(() => {
    if (!eventData) {
      fetchingEventData();
    }

    return () => {
      dispatch({
        type: 'SET_EVENT_DATA',
        payload: null,
      });

      dispatch({
        type: 'SET_WIDGET_SOURCE',
        payload: null,
      });
    };
  }, [query]);

  useEffect(() => {
    if (!eventData) return;

    const timer = setTimeout(() => {
      const isPopupVisited = sessionStorage.getItem('eventWidgetVisited');

      if (!isPopupVisited) {
        sessionStorage.setItem('eventWidgetVisited', true);

        dispatch({
          type: 'SET_WIDGET_SOURCE',
          payload: sourceType.eventPopup,
        });
      }
    }, 20000);

    return () => {
      clearTimeout(timer);
    };
  }, [eventData]);

  return <></>;
}

export default EventPopup;
