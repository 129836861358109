import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import { Dialog, Transition } from '@headlessui/react';
import useMediaQuery from '@utils/hooks/useMediaQuery';

function VideoPopup({ isOpen, closeModal, videoLink, directPlay = false }) {
  const isMobile = useMediaQuery('(max-width: 767.98px)');

  return (
    <Transition appear show={isOpen}>
      <Dialog
        as="div"
        className="z-99 fade modal fixed inset-0 overflow-y-auto"
        onClose={closeModal}
        style={{ zIndex: 100 }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-flex w-full max-w-md transform flex-col items-center overflow-hidden rounded-2xl bg-white py-2 text-left align-middle shadow-xl transition-all lg:max-w-xl">
              {!isMobile ? (
                <Dialog.Title as="div" className="px-2 py-4">
                  <div className="player-wrapper">
                    <div className="react-player" style={{ width: '100%', height: '100%' }}>
                      <ReactPlayer
                        url={videoLink}
                        controls
                        playing={directPlay}
                        config={{
                          youtube: {
                            playerVars: { showinfo: 1 },
                          },
                        }}
                        width={580}
                        height={340}
                      />
                    </div>
                  </div>
                </Dialog.Title>
              ) : (
                <Dialog.Title as="div" className="pb-4">
                  <div className="player-wrapper">
                    <div className="react-player" style={{ width: '100%', height: '100%' }}>
                      <ReactPlayer
                        url={videoLink}
                        controls
                        playing={directPlay}
                        config={{
                          youtube: {
                            playerVars: { showinfo: 1 },
                          },
                        }}
                        height={240}
                        width={380}
                      />
                    </div>
                  </div>
                </Dialog.Title>
              )}

              <button
                className="text-md mt-2 mb-2 w-fit rounded-lg bg-red px-4 py-2 text-white md:text-lg lg:py-2 lg:px-12"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default VideoPopup;
