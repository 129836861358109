import { useState, useEffect } from 'react';
import { dateFormat, eventDateTimeFormat } from '@utils/functions/readingTime';
import { getStrapiURL } from '@lib/api';
import axios from 'axios';
import { getImgUrl } from '@utils/functions/getImgUrl';
import useEventCountDown from '@components/reuseable/EventCountDown';
import { useDispatch } from '@globalStore/storeProvider';
import ContactUs, { sourceType } from '../widgets/ContactUs';
import useStorage from '@utils/hooks/useStorage';
import dayjs from 'dayjs';
import { extractLineInDoubleCurlyBraces, genRegisterRandomNumber } from '@utils/functions/throttle';
import { useRouter } from 'next/router';
import { RxCross2 } from 'react-icons/rx';
import { COURSE_TYPE_SLUG_MAPPING } from '@lib/constants';
import { BiError } from 'react-icons/bi';
import { BsArrowRepeat } from 'react-icons/bs';
import { checkCityBasedSlug } from '@utils/helper';

export async function fetchEventData(courseSlug = false, pathname = '', url = '') {
  try {
    const { isCitySlug, slug } = courseSlug && checkCityBasedSlug(courseSlug);

    const slugFilter = isCitySlug ? slug : courseSlug;

    const eventType =
      pathname.startsWith('/bytes') || pathname.startsWith('/degree') ? 'AMA' : 'MasterClass';

    const apiBaseUrl = getStrapiURL();

    const URL = url
      ? url
      : courseSlug
      ? `${apiBaseUrl}/api/master-classes?populate=*,instructor.DisplayPicture,instructor.CompanyImage,eventImage,courses,instructor.pastCompanies,instructor.pastCompanies.companyImage&sort=eventDate:ASC&filters[$and][1][courses][slug][$eq]=${slugFilter}`
      : `${apiBaseUrl}/api/master-classes?populate=*,instructor.DisplayPicture,instructor.CompanyImage,eventImage,courses,instructor.pastCompanies,instructor.pastCompanies.companyImage&sort=eventDate:ASC&filters[$and][0][eventType][$eq]=${eventType}`;

    const date = new Date();
    const today = dateFormat(date);

    const event = await axios.get(
      `${URL}&filters[$and][0][eventDate][$gte]=${today}&filters[$and][1][slug][$ne]=how-can-an-iit-certification-propel-your-tech-career`
    );

    const eventData = event.data?.data[0]?.attributes;

    if (!!url && !eventData) {
      return null;
    }

    if (!eventData) {
      const newURL = `${apiBaseUrl}/api/master-classes?populate=*,instructor.DisplayPicture,instructor.CompanyImage,eventImage,courses,instructor.pastCompanies,instructor.pastCompanies.companyImage&sort=eventDate:ASC`;
      return fetchEventData(courseSlug, pathname, newURL);
    }

    const registerLink = eventData?.registerCTALink || eventData?.registerLink;
    const eventId = registerLink?.match(/\d+/g)?.[0];

    eventData['eventId'] = eventId;

    return eventData;
  } catch (error) {
    console.log('WIDGET_ERROR', error);
  }
}

const BytesWidget = ({
  openByStripBanner = false,
  closeByStripBanner,
  eventsData = null,
  isModalOpenByRCB = false,
  formSubmitStatus,
  setFormSubmitStatus,
  courseInterest,
  pgCourse = false,
  oneClickHandler,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(openByStripBanner || isModalOpenByRCB || false);
  const [events, setEvents] = useState(eventsData);

  const eventStartTime = eventDateTimeFormat(events?.eventDate, events?.startTime);
  const eventEndTime = eventDateTimeFormat(events?.eventDate, events?.endTime);

  const { showJoin, isEnded } = useEventCountDown(eventStartTime, eventEndTime);

  const registeredCount = genRegisterRandomNumber(eventStartTime);

  const { getItemFromLocal } = useStorage();

  const isRegisteredExist = getItemFromLocal('isRegistered');
  const registeredEventId = (isRegisteredExist && JSON.parse(isRegisteredExist)) || [];

  const localFormData = getItemFromLocal('rcb_form_data');

  const { query, pathname } = useRouter();

  const dispatch = useDispatch();
  const eventType = events?.EventType || events?.eventType;
  const COURSE_INTEREST_SLUG = COURSE_TYPE_SLUG_MAPPING[courseInterest];

  const closeModal = () => {
    setIsModalOpen(false);
    closeByStripBanner && closeByStripBanner(false); // to remove component mounting from strip banner to reduce rendering
    sessionStorage.removeItem('sourceType');
    setFormSubmitStatus &&
      setFormSubmitStatus((prev) => {
        return { ...prev, eventConfirmation: false, successful: false };
      });

    dispatch({
      type: 'SET_WIDGET_SOURCE',
      payload: null,
    });
  };

  const setCourseData = () => {
    dispatch({
      type: 'SET_EVENT_DATA',
      payload: { ...events, source: sourceType.eventPopup },
    });

    sessionStorage.setItem('eventWidgetVisited', true);
  };

  const redirectToSuccessScreen = () => {
    isModalOpenByRCB &&
      setFormSubmitStatus &&
      setFormSubmitStatus((prev) => {
        return { ...prev, eventConfirmation: false, successful: true };
      });

    dispatch({
      type: 'SET_EVENT_DATA',
      payload: null,
    });
    sessionStorage.removeItem('sourceType');
  };

  useEffect(() => {
    if (!eventsData || !Object.keys(eventsData).length) {
      (async () => {
        const res = await fetchEventData(query?.courseSlug || COURSE_INTEREST_SLUG, pathname);

        if (!res) {
          redirectToSuccessScreen();
        }
        const eventLiveTime =
          eventDateTimeFormat(res?.eventDate, res?.startTime)?.getTime() - 30 * 60 * 1000;

        if (registeredEventId?.includes(res?.eventId) && eventLiveTime > Date.now()) {
          setEvents(null);
          redirectToSuccessScreen();
        } else {
          setEvents(res);
        }
      })();
    }
  }, [query]);

  useEffect(() => {
    if (!events || !Object.keys(events).length) {
      return;
    }

    if (isModalOpenByRCB) {
      setCourseData();
    }

    const timer = setTimeout(() => {
      if (sessionStorage.getItem('eventWidgetVisited')) {
        return;
      }

      sessionStorage.setItem('sourceType', sourceType.eventPopup);

      setCourseData();

      setIsModalOpen(true);
    }, 20000);

    return () => {
      clearTimeout(timer);
    };
  }, [events]);

  if (!events || !Object.keys(events).length || (openByStripBanner && !eventsData)) return null;

  const { DisplayPicture, Designation, Name, CompanyImage } =
    events?.instructor?.data[0]?.attributes;

  const isThisEventAlreadyRegister = registeredEventId?.includes(events?.eventId);

  return (
    <>
      {isModalOpen && !isEnded && (
        <div
          className={
            isModalOpenByRCB
              ? 'flex flex-col gap-y-5'
              : 'fixed inset-0 z-[114] flex items-center justify-center'
          }
        >
          {!isModalOpenByRCB && (
            <div
              className="modal-overlay absolute h-full w-full bg-gray-900 opacity-50"
              onClick={closeModal}
            ></div>
          )}
          {!isThisEventAlreadyRegister && isModalOpenByRCB && (
            <div className="relative flex flex-col items-start gap-y-2 rounded bg-white py-4 pl-6 shadow-lg">
              <p className="flex items-end gap-x-1 font-satoshi-medium text-lg font-semibold">
                <img src="/assets/icons/Thumb_up.png" alt="" className="h-8" />
                Thank You
              </p>
              <p className="leading-5	text-[#5E6E88]">
                We have received your callback request. Our academic counsellor will reach out to
                you within next <b>24 hrs.</b>
              </p>

              {!pgCourse && (
                <button
                  className={`modal-close absolute -top-4 -right-4 z-30 ${
                    !isThisEventAlreadyRegister ? 'text-white' : 'text-black'
                  } `}
                  onClick={closeModal}
                >
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#DCDCDC]">
                    <span className="text-4xl font-thin text-black">
                      <RxCross2 size={22} />
                    </span>
                  </div>
                </button>
              )}
            </div>
          )}

          <div className={`relative ${isModalOpenByRCB ? 'w-full' : 'top-8 w-11/12 md:max-w-lg'}`}>
            <div className="modal-container mx-auto rounded bg-white shadow-lg">
              {!isThisEventAlreadyRegister && (
                <span
                  className={`absolute ${
                    isModalOpenByRCB ? '-top-3' : '-top-2'
                  } -left-2 z-30 rounded-r-sm py-1 px-6 font-satoshi-bold text-[14px] font-bold  leading-5 text-white`}
                  style={{ backgroundColor: '#684AA7' }}
                >
                  {eventType === 'Masterclasses' || eventType === 'MasterClass'
                    ? 'MASTERCLASS'
                    : `${eventType} SESSION`}
                </span>
              )}
              <div className="relative w-full">
                {!isThisEventAlreadyRegister && events && (
                  <div className="flex items-center justify-between bg-[#2d2d2d] text-white">
                    <div className="flex w-3/5 flex-col items-start gap-y-2 bg-gradient-to-r from-[#3F3F40] to-[#202020] pl-4 pr-2 pt-6 pb-3">
                      <p
                        className={`${
                          isModalOpenByRCB ? '' : 'md:text-lg'
                        } text-base font-semibold leading-5 md:font-satoshi-medium`}
                      >
                        {extractLineInDoubleCurlyBraces(events?.shortTitle || events?.title)}
                      </p>
                      <p className="rounded-md bg-red px-3 py-1 text-xs md:text-[14px] md:leading-4">
                        {dayjs(`${events?.eventDate}T${events?.startTime}`).format(
                          'MMM DD | hh:mm A'
                        )}
                      </p>
                      <p className="text-xs leading-4 text-green-cyan md:text-[16px]">
                        Attend live masters class to get flat 15% OFF on our courses.
                      </p>
                    </div>
                    <div className="flex w-2/5 flex-col items-center justify-center gap-y-2 bg-gradient-to-b from-[#3F3F40] to-[#232323] p-2">
                      <img
                        className="h-16 w-16 rounded-full object-contain md:h-20 md:w-20"
                        src={getImgUrl(DisplayPicture)}
                      />

                      <img
                        src={getImgUrl(CompanyImage)}
                        className="h-6 w-24 rounded-md bg-white object-contain px-2"
                      />
                      <div className="flex flex-col items-center gap-y-1 text-center">
                        <p className="border-b border-red text-[14px] leading-4">ft. {Name}</p>
                        <p className="text-xs font-thin">{Designation}</p>
                      </div>
                    </div>
                  </div>
                )}
                {(isThisEventAlreadyRegister || !isModalOpenByRCB) && !pgCourse && (
                  <button
                    className={`modal-close absolute -top-4 -right-4 z-30 ${
                      !isThisEventAlreadyRegister ? 'text-white' : 'text-black'
                    } `}
                    onClick={closeModal}
                  >
                    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#DCDCDC]">
                      <span className="text-4xl font-thin text-black">
                        <RxCross2 size={22} />
                      </span>
                    </div>
                  </button>
                )}
              </div>

              {localFormData && isModalOpenByRCB ? (
                <>
                  <p className="whitespace-nowrap py-4 text-center text-xs md:mt-0 md:text-sm">
                    Hurry Up! Only <span className="text-red">{registeredCount} seats left.</span>
                  </p>
                  <div className={`flex w-full flex-col items-center justify-center gap-y-2 pb-6`}>
                    {formSubmitStatus?.error && (
                      <div className="mt-2 ml-2 flex items-center text-xs font-extrabold text-red md:text-sm ">
                        <BiError className="mr-1 text-lg" />
                        {formSubmitStatus?.error}
                      </div>
                    )}
                    <button
                      onClick={() => oneClickHandler && oneClickHandler()}
                      className={`flex w-11/12 items-center justify-center rounded-md bg-red px-4 py-2 font-satoshi-medium text-lg font-semibold normal-case text-white disabled:opacity-80`}
                    >
                      {formSubmitStatus?.loading ? (
                        <BsArrowRepeat className="animate-spin text-2xl" />
                      ) : (
                        'Register with 1-Click'
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <div>{events && <ContactUs eventPage />}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BytesWidget;
