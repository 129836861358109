import React, { useEffect, useState, createRef } from 'react';
import { RxCross2 } from 'react-icons/rx';
import ContactUs, { sourceType } from './ContactUs';
import { useStore } from '@globalStore/storeProvider';
import { useRouter } from 'next/router';
import useStorage from '@utils/hooks/useStorage';
import cn from 'classnames';
import RCBSuccessScreen from '../forms/NewCallBackForm/RCBSuccessScreen';
import ApplicationForm from '../forms/ApplicationForm';
import Image from 'next/image';
import iitLogo from '@assets/new-iit-short-logo-black.png';

const chipData = [
  {
    title: 'Avg Salary',
    data: '8 LPA',
  },
  {
    title: 'Highest Salary',
    data: '33 LPA',
  },
  {
    title: 'Students Placed',
    data: '3000+',
  },
];

export const isSuccessScreen = createRef();

function LeadCapturePopup() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { widgetSource } = useStore();
  const router = useRouter();

  const { getItem, getItemFromLocal } = useStorage();

  const closeHandler = () => {
    setIsModalOpen(false);
  };

  const showPopup = () => {
    const popupViewed = getItem('popup_viewed');
    const isDemoClass = getItem('demo_class');

    if (!popupViewed || isDemoClass || widgetSource || router.pathname === '/_error') return null;

    setIsModalOpen(true);
    sessionStorage.setItem('lcp2', true);
  };

  useEffect(() => {
    let timerId = null;

    const isLCP2Viewed = getItem('lcp2');

    const localFormData = getItemFromLocal('rcb_form_data');

    if (isLCP2Viewed || localFormData) return null;

    timerId = setTimeout(showPopup, 60000);

    return () => clearTimeout(timerId);
  }, [widgetSource, router]);

  if (!isModalOpen) return null;

  return (
    <div
      className="fixed inset-0 z-[1111] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-50 px-4 lg:h-screen lg:px-0"
      onClick={closeHandler}
    >
      <div
        className="relative flex max-w-lg flex-col"
        ref={isSuccessScreen}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={`modal-close absolute -top-3 -right-2 z-30 text-white`}
          onClick={closeHandler}
        >
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#DCDCDC]">
            <span className="text-4xl font-thin text-black">
              <RxCross2 size={22} />
            </span>
          </div>
        </button>

        <div className="flex flex-col items-center gap-y-[12px] rounded rounded-t-lg bg-gradient-to-r from-[#0E0E0E] to-[#333333] pt-[20px] text-white md:pt-[12px]">
          <p className="font-satoshi-medium text-xl font-semibold leading-3 md:text-2xl">
            100% Job Assurance led Programs
          </p>
          <div className="flex items-center gap-x-2">
            <p className="font-satoshi-medium text-base md:text-[14px] md:leading-3">
              Taught by instructors from
            </p>
            <img src="/assets/companies.png" alt="" className="h-6" />
          </div>
          <p className="rounded bg-[#5755BC] px-[16px] py-[8px] font-satoshi-medium text-[14px] leading-5 md:py-[4px]">
            🔥 Limited seats for up to 15% Scholarship
          </p>
          <div className="grid w-full grid-cols-4 place-items-end items-end justify-items-center bg-[#4A4A4A7D] px-3 pb-3	pt-2">
            <div className="flex flex-col gap-y-[4px] md:gap-y-[2px]">
              <p className="font-satoshi-regular text-[12px] leading-3">Certification from</p>
              <Image
                src={iitLogo}
                width={106}
                height={31}
                objectFit="contain"
                className="overflow-hidden rounded bg-white"
              />

              {/* <img src="" alt="" className="h-7" /> */}
            </div>
            {chipData.map(({ title, data }) => (
              <div className="flex flex-col gap-y-[4px]" key={data}>
                <p className="font-satoshi-bold text-[14px] !leading-5">{data}</p>
                <span className="font-satoshi-regular text-[12px] leading-3">{title}</span>
              </div>
            ))}
          </div>
        </div>

        <div
          className={cn('relative flex flex-col gap-y-[16px] rounded-b-lg bg-white p-[24px] pr-0')}
        >
          <ApplicationForm
            SuccessScreen={RCBSuccessScreen}
            source={sourceType.lcp2}
            buttonCTA={'Talk to Admission Team'}
            formContainerStyle={cn('pr-[24px] slim-scroll md:max-h-[48vh]')}
          />
        </div>
      </div>
    </div>
  );
}

export default LeadCapturePopup;
