import { getImgUrl } from '@utils/functions/getImgUrl';
import styles from './styles.module.css';
import Image from 'next/image';
import Link from 'next/link';
import { PLAN_TYPE } from '@lib/constants';
const Footer = ({ data, logo, coursesSummary }) => {
  const contactDetails = {
    socialMediaLinks: [
      {
        link: data?.SocialLinks?.facebook,
        icon: '/assets/icons/Facebook - Negative-1.png',
        name: 'facebook',
      },
      {
        link: data?.SocialLinks?.instagram,
        icon: '/assets/icons/Instagram - Negative.png',
        name: 'instagram',
      },
      {
        link: data?.SocialLinks?.linkedin,
        icon: '/assets/icons/LinkedIn - Negative.png',
        name: 'linkedin',
      },
      {
        link: data?.SocialLinks?.twitter,
        icon: '/assets/icons/Twitter - Negative.png',
        name: 'twitter',
      },
      {
        link: data?.SocialLinks?.youtube,
        icon: '/assets/icons/YouTube - Negative.png',
        name: 'youtube',
      },
      {
        link: data?.SocialLinks?.telegram,
        icon: '/assets/icons/Telegram.png',
        name: 'telegram',
      },
    ],
  };

  return (
    <div className="color-white flex w-full flex-col justify-evenly bg-black p-8 lg:px-20">
      <div className={`${styles.gridCard4} `}>
        {/* contact details */}
        <div className="flex flex-col items-start justify-evenly">
          <Link href="/">
            <a className="flex">
              <Image
                src={getImgUrl(logo)}
                alt="AlmaBetter"
                height={40}
                width={150}
                objectFit="contain"
              />
            </a>
          </Link>
          <div className="mt-4 flex text-base text-white lg:hidden">
            Made with{' '}
            <Image
              src="/assets/icons/heart_front_04 2@2x.png"
              alt="heart"
              height={25}
              width={25}
              loading="lazy"
              className="mx-1"
            />
            in Bengaluru, India{' '}
          </div>
          <ul className="mt-5 hidden lg:block">
            <li className="font-satoshi-medium text-white opacity-50">Contact Us</li>
            <li className="font-satoshi-medium text-sm text-white">
              <a href={`mailto:${data.companyEmail}`}>{data.companyEmail}</a>
            </li>
            {data?.companyContact?.map((item) => (
              <li className="py-1 font-satoshi-medium text-sm text-white" key={item.id}>
                <a href={`tel:${item.contact}`}>{item.contact}</a>
              </li>
            ))}
          </ul>
          <ul className="mt-5 hidden lg:block">
            <li className="font-satoshi-medium text-white opacity-50">Official Address</li>
            <li className="font-satoshi-medium text-sm text-white">{data.officialAddress}</li>
          </ul>
          <ul className="mt-5 hidden lg:block">
            <li className="font-satoshi-medium text-white opacity-50">Communication Address</li>
            <li className="font-satoshi-medium text-sm text-white">{data.communicationAddress}</li>
          </ul>
          <ul className="mt-5 mb-5 hidden lg:block">
            <li className="font-satoshi-medium text-white opacity-50">Follow Us</li>
            <div className="mt-4 flex flex-row items-start gap-4 font-satoshi-medium">
              {contactDetails.socialMediaLinks.length > 0 &&
                contactDetails.socialMediaLinks.map((item, index) => (
                  <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
                    <Image src={item.icon} alt="" height={30} width={30} loading="lazy" />
                  </a>
                ))}
            </div>
          </ul>
        </div>
        {Object.entries(data.Footer).map((item, index) => (
          <div key={index} className="mt-8 flex flex-col items-start justify-evenly lg:mt-0">
            <ul>
              {item[1]?.title?.toLowerCase() === 'courses' ? (
                <div>
                  <li className="py-1 font-satoshi-medium text-white lg:py-3">{item[1].title}</li>
                  {coursesSummary
                    .map((_) => _.courses)
                    .flat()
                    .map((course) => (
                      <li
                        key={course?.slug}
                        className="py-2 pr-2 font-satoshi-medium text-sm text-white opacity-50"
                      >
                        <Link
                          href={`${
                            course?.planType === PLAN_TYPE.MASTERS_DEGREE
                              ? '/degrees/'
                              : '/courses/'
                          }${course.slug}`}
                          as={`${
                            course?.planType === PLAN_TYPE.MASTERS_DEGREE
                              ? '/degrees/'
                              : '/courses/'
                          }${course.slug}`}
                        >
                          {course?.courseName}
                        </Link>
                      </li>
                    ))}
                </div>
              ) : (
                <>
                  <li className="py-1 font-satoshi-medium text-white lg:py-3 ">{item[1].title}</li>
                  {item[1].innerColumn.map((col, index) => (
                    <li
                      key={index}
                      className="py-2 pr-2 font-satoshi-medium text-sm text-white opacity-50"
                    >
                      {col?.slug?.includes('http') ? (
                        <a href={`${col.slug}`}>{col.title}</a>
                      ) : (
                        <a
                          href={
                            item[1].title === 'Courses' ? `/courses/${col.slug}` : `/${col.slug}`
                          }
                        >
                          {col.title}
                        </a>
                      )}
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        ))}
      </div>
      {/* mobile ui */}
      <div className="flex flex-col items-start justify-evenly">
        {/* <a href='/'>
          <img src='/assets/images/logov2.png' alt='AlmaBetter' />
        </a> */}
        <ul className="block lg:hidden">
          <li className="font-satoshi-medium text-white opacity-50">Contact Us</li>
          <li className="py-1 font-satoshi-medium text-sm text-white">
            <a href={`mailto:${data.companyEmail}`}>{data.companyEmail}</a>
          </li>
          {data?.companyContact?.map((item) => (
            <li className="py-1 font-satoshi-medium text-sm text-white" key={item.id}>
              <a href={`tel:${item.contact}`}>{item.contact}</a>
            </li>
          ))}
        </ul>
        <ul className="mt-5 block lg:hidden">
          <li className="font-satoshi-medium text-white opacity-50">Official Address</li>
          <li className="font-satoshi-medium text-sm text-white">{data.officialAddress}</li>
        </ul>
        <ul className="mt-5 block lg:hidden">
          <li className="font-satoshi-medium text-white opacity-50">Communication Address</li>
          <li className="font-satoshi-medium text-sm text-white">{data.communicationAddress}</li>
        </ul>
        <ul className="mt-5 mb-5 block lg:hidden">
          <li className="font-satoshi-medium text-white opacity-50">Follow Us</li>
          <div className="mt-4 flex flex-row items-start gap-4 font-satoshi-medium">
            {contactDetails.socialMediaLinks.length > 0 &&
              contactDetails.socialMediaLinks.map((item, index) => (
                <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
                  <Image
                    src={item.icon}
                    alt={item.name}
                    height={30}
                    width={30}
                    loading="lazy"
                    className="h-[30px]"
                  />
                </a>
              ))}
          </div>
        </ul>
      </div>
      <div className="mt-10 hidden font-satoshi-medium text-base text-white lg:flex">
        Made with{' '}
        <Image
          src="/assets/icons/heart_front_04 2@2x.png"
          alt="heart"
          height={25}
          width={30}
          className="mx-1"
        />
        in Bengaluru, India{' '}
      </div>
      <div className="mt-1 flex justify-center">
        <hr
          className="item-center w-full"
          style={{ border: '1px solid #282f34', transform: 'rotate(0.05deg)' }}
        />
      </div>
      <div className="flex flex-row items-center justify-between p-4 font-satoshi-medium text-sm text-gray-1">
        <div className="text-white opacity-50">&copy; {new Date().getFullYear()} AlmaBetter</div>
        {/* <a href='/privacy-policy' className='text-white opacity-50'>
          Privacy Statement &nbsp;&nbsp;&nbsp;
          <a href='/terms'>Terms And Conditions</a>
        </a> */}
      </div>
    </div>
  );
};

export default Footer;
