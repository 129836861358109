import { useState, useEffect, useRef } from 'react';

const useEventCountDown = (startTime, endTime) => {
  const [timerData, setTimerData] = useState({
    timerDays: 0,
    timerHours: 0,
    timerMinutes: 0,
    timerSeconds: 0,
  });
  const [EventStatus, setEventStatus] = useState(null);
  const [isEnded, setIsEnded] = useState(false);
  const [showJoin, setShowJoin] = useState(false);

  let interval = useRef();

  const setupTimer = (distance) => {
    const twoDigi = (time) => ('0' + Math.floor(time)).slice(-2);
    setTimerData({
      timerDays: twoDigi(distance / (24 * 60 * 60 * 1000)),
      timerHours: twoDigi((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)),
      timerMinutes: twoDigi((distance % (60 * 60 * 1000)) / (1000 * 60)),
      timerSeconds: twoDigi((distance % (60 * 1000)) / 1000),
    });
  };

  const eventStartTimer = () => {
    const countDownStart = new Date(startTime).getTime();
    const countDownDate = new Date(endTime).getTime();

    interval.current = setInterval(() => {
      const now = new Date().getTime();
      const distance = now - countDownStart;

      // distance according to live till end time
      if (now < countDownStart) {
        setEventStatus('STARTING');
        setupTimer(countDownStart - now);
      } else if (distance / (1000 * 60) >= 60) {
        // Stop Timer
        clearInterval(interval.current);
        setIsEnded(true);
        setEventStatus('END');
      } else if (now >= countDownStart) {
        setShowJoin(true);
        setEventStatus('LIVE');
      }
    }, 1000);
  };

  useEffect(() => {
    if (startTime && endTime) !isEnded && eventStartTimer();

    return () => clearInterval(interval.current);
  }, [isEnded, startTime, endTime]);

  useEffect(() => {
    if (
      Number(timerData.timerDays) === 0 &&
      Number(timerData.timerHours) === 0 &&
      Number(timerData.timerMinutes) <= 29 &&
      Number(timerData.timerSeconds) > 0
    ) {
      setShowJoin(true);
    } else {
      setShowJoin(false);
    }
  }, [timerData]);

  return { timerData, EventStatus, isEnded, showJoin };
};

export default useEventCountDown;
