import { createContext, useContext, useReducer } from 'react';

export const StoreContext = createContext();

export const StoreProvider = ({ reducer, initialState, children }) => (
  <StoreContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StoreContext.Provider>
);

export const useStore = () => {
  const [states] = useContext(StoreContext);
  return states;
};
export const useDispatch = () => {
  const [states, dispatch] = useContext(StoreContext);
  return dispatch;
};
