// Button.stories.js
import { Button } from './Button';

export default {
  title: 'Example/Button',
  component: Button,
  parameters: {
    layout: 'centered',
  },
  tags: ['autodocs'],
  argTypes: {
    backgroundColor: { control: 'color' },
  },
};

const Template = (args) => <Button {...args} />;

const PrimaryAgrs = {
  primary: true,
  label: 'Button CTA',
};

export const Primary = (props) => <Template {...PrimaryAgrs} {...props} />;

const SecondaryArgs = {
  primary: false,
  label: 'Button CTA',
};

export const Secondary = (props) => <Template {...SecondaryArgs} {...props} />;

const SecondaryColorArgs = {
  backgroundColor: 'torch-red-100',
  borderColor: 'torch-red-300',
  labelColor: 'torch-red-700',
  customStyle:
    'border hover:bg-torch-red-200 hover:text-torch-red-800 disabled:bg-torch-red-50 disabled:border-torch-red-50 disabled:text-torch-red-400',
};

export const SecondaryColor = (props) => <Secondary {...SecondaryColorArgs} {...props} />;

const HierarchyBtnArgs = {
  primary: true,
  hierarchyBtn: true,
  label: 'Button CTA',
};

export const HierarchyPrimary = (props) => <Template {...HierarchyBtnArgs} {...props} />;
