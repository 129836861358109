import { useRouter } from 'next/router';
import { useRef, useEffect } from 'react';

export const usePreviousRoute = () => {
  const { asPath } = useRouter();
  const ref = useRef();

  useEffect(() => {
    ref.current = asPath;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  return ref.current ?? 'N/A';
};
