import React from 'react';
import PropTypes from 'prop-types';

const SIZE_CONST = {
  large: 'text-[14px] leading-5 py-[4px] px-[12px]',
  medium: 'text-[14px] leading-5 py-0.5 px-2.5',
  small: 'text-[12px] leading-[18px] py-[2px] px-[8px]',
};

export function Badge({
  primary,
  backgroundColor,
  size,
  label,
  labelColor,
  borderColor,
  capitalCase,
  customStyle,
  ...props
}) {
  const mode = primary
    ? 'bg-[#F9E0E2] text-red border border-[#F98991]'
    : 'border-[#EAECF0] border bg-[#F9FAFB]';

  const textCase = capitalCase ? 'uppercase' : 'normal-case';

  return (
    <span
      className={`rounded-full font-satoshi-medium ${mode} ${SIZE_CONST[size]} ${textCase} ${customStyle}`}
      {...props}
      style={{ backgroundColor: backgroundColor, color: labelColor, borderColor }}
    >
      {props.children}
      {label}
    </span>
  );
}

Badge.propTypes = {
  primary: PropTypes.bool,

  backgroundColor: PropTypes.string,

  size: PropTypes.oneOf(['small', 'medium', 'large']),

  label: PropTypes.string.isRequired,

  labelColor: PropTypes.string,

  borderColor: PropTypes.string,

  capitalCase: PropTypes.bool,

  customStyle: PropTypes.string,

  onClick: PropTypes.func,
};

Badge.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'medium',
  labelColor: null,
  borderColor: null,
  capitalCase: false,
  onClick: undefined,
  customStyle: '',
};
