import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';

function GlobalFormHeader({ formTitle, formDesc, closeModal }) {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-11 cursor-default">
        <p className="font-satoshi-bold text-[20px] leading-[30px] text-black">{formTitle}</p>
        <p className="font-satoshi-regular text-[16px] leading-6 text-gray-navigation">
          {formDesc}
        </p>
      </div>

      {closeModal && (
        <div onClick={closeModal} className="cursor-pointer">
          <IoCloseOutline size={24} className="text-gray-navigation" />
        </div>
      )}
    </div>
  );
}

export default GlobalFormHeader;
