function toTitleCase(str) {
  if (str.includes(' ')) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return str.slice(0, 1).toUpperCase() + str.slice(-str.length + 1);
}

function createBreadCrumbSchema(routerPath) {
  let appendedURL = 'https://www.almabetter.com';

  const base = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
  };

  const itemListElement = routerPath?.map?.((item, i) => {
    const structure = {
      '@type': 'ListItem',
      position: i + 1,
      name: toTitleCase(item.text),
    };

    if (i < routerPath.length - 1) {
      structure['item'] = appendedURL + item.href;
    }

    return structure;
  });

  base['itemListElement'] = itemListElement;

  return base;
}

export default createBreadCrumbSchema;
