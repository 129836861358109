import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

export function formatTime(date) {
  const formattedDate = dayjs.utc(date).format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z');
}

export const addToCalendar = ({ topic, startTime, endTime, details, location }) => {
  let calendarUrl = 'https://calendar.google.com/calendar/render';
  calendarUrl += '?action=TEMPLATE';
  calendarUrl += '&text=' + encodeURIComponent(topic);
  calendarUrl += '&dates=' + formatTime(startTime) + '/' + formatTime(endTime);
  if (details) calendarUrl += '&details=' + encodeURIComponent(details);
  if (location) calendarUrl += '&location=' + encodeURIComponent(location);
  return window.open(calendarUrl);
};

export function DateTimeFormatter(date = '', format = '', calendar = false) {
  return calendar
    ? dayjs(date).calendar(null, {
        sameDay: `${format} [Today]`,
        nextDay: `${format} [Tomorrow]`,
        nextWeek: `${format} ddd Do`,
        lastDay: `${format} [Yesterday]`,
        sameElse: `${format} Do MMM, YYYY`,
      })
    : dayjs(date).format(format || 'DD MMM, YYYY');
}

export const ExtractTitleAndSubTitle = (heading, extractNum) => {
  const splittedHeading = `${heading}`.split(' ');

  const extractedLength = splittedHeading.length - extractNum;

  const newHeading = splittedHeading.slice(0, extractedLength).join(' ');

  const highlightedText = splittedHeading.slice(extractedLength, splittedHeading.length).join(' ');

  return {
    title: newHeading,
    subTitle: highlightedText,
  };
};
export const formatTrackingText = (e) =>
  e.currentTarget.innerText.toLowerCase().split(' ').join('_');
