import { getPathArray } from '@utils/functions/getPathArray';
import Link from 'next/link';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Fragment, useMemo } from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import createBreadCrumbSchema from '@lib/createBreadCrumbSchema';

const BreadCrumb = ({ isBytesPage }) => {
  const { asPath } = useRouter();
  const pathArray = useMemo(() => getPathArray(asPath), [asPath]);

  return (
    <>
      <Head>
        <script type="application/ld+json">
          {JSON.stringify(createBreadCrumbSchema(pathArray), null, 2)}
        </script>
      </Head>
      <div className="flex max-w-[100vw] items-center overflow-hidden font-satoshi-medium">
        {pathArray.map(({ href, text }, index) => {
          return (
            <Fragment key={href}>
              <Link href={href} passHref>
                <a
                  className={`cursor-pointer  break-words text-[0.85rem] font-normal capitalize leading-5 hover:text-red md:text-sm ${
                    index !== pathArray.length - 1
                      ? isBytesPage
                        ? 'text-white'
                        : 'text-bytes-gray-7'
                      : 'text-red'
                  } ${text.length > 9 && 'truncate'}`}
                >
                  {text}
                </a>
              </Link>
              {index !== pathArray.length - 1 && (
                <MdOutlineKeyboardArrowRight
                  className={isBytesPage ? 'text-white' : 'text-bytes-gray-7'}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default BreadCrumb;
