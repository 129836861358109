import ReactMarkdown from 'react-markdown';
import { useRouter } from 'next/router';
import styles from './styles.module.css';
import Modal from './Modal';
import useStorage from '../../../utils/hooks/useStorage';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Link from 'next/link';
import { useDispatch } from '@globalStore/storeProvider';
import { sourceType } from '@components/shared/widgets/ContactUs';
import { Fragment, useState } from 'react';
import VideoPopup from '@components/shared/VideoPopup';
import { Primary, Secondary } from 'stories/Button.stories';
import { PrimaryBadge } from 'stories/Badge.stories';
dayjs.extend(customParseFormat);

const EventsCard = ({
  bgColor = 'blue-7',
  slug,
  title,
  eventDate,
  description,
  eventImage,
  startTime,
  endTime,
  eventId,
  category,
  eventType,
  videoLink,
  // isWatchVideo = false,
  registerLink,
  workshops,
  registerCTALink,
  dateAndTime,
  instructor,
  recordedVideoLink,
}) => {
  const { getItem } = useStorage();
  const [isOpen, setIsOpen] = useState(false);
  const currentUtm = getItem('utm') && JSON.parse(getItem('utm'));

  // set query string for redirect
  let queryString = '';
  for (const property in currentUtm) {
    queryString += `&${property}=${currentUtm[property]}`;
  }
  const time =
    startTime && endTime
      ? `${dayjs(startTime, 'HH:mm:ss.SSS').format('h:mm A')} -
              ${dayjs(endTime, 'HH:mm:ss.SSS').format('h:mm A')}`
      : null;

  const eventTypePaths = {
    MasterClass: '/events/master-class/',
    AMA: '/events/master-class/',
    Workshops: workshops?.hasOwnProperty(slug) ? '/events/workshop/' : '/events/',
  };

  const path = eventTypePaths[eventType] || '/events/';

  const router = useRouter();
  let buttonText = '';
  const dispatch = useDispatch();
  // if (router.pathname.includes('/[eventSlug]') && category === 'upcoming') {
  //   buttonText = 'Register';
  // }
  if (router.pathname.includes('/[eventSlug]') && category === 'past' && videoLink) {
    buttonText = 'Watch Video';
  }
  if (!router.pathname.includes('/[eventSlug]')) {
    buttonText = 'Explore Event';
  }

  function closeModal() {
    setIsOpen(false);
    document.getElementById('modal').classList.remove('modal-open');
  }

  function openModal() {
    setIsOpen(true);
    document.getElementById('modal').classList.add('modal-open');
  }

  const { getItemFromLocal } = useStorage();

  const localFormData = getItemFromLocal('rcb_form_data');

  return (
    <div className={styles.eventContainer}>
      <div className="relative mb-8 w-full rounded-md lg:mr-8 lg:mb-0 lg:h-full lg:w-1/2">
        <img
          src={eventImage?.data?.attributes?.url}
          alt=""
          className="h-full w-full rounded-md"
          loading="lazy"
        />
        {category === 'past' &&
          (recordedVideoLink?.includes('youtube.com') ||
            recordedVideoLink?.includes('youtu.be')) && (
            <img
              src="/assets/images/play.svg"
              alt="play-icon"
              onClick={openModal}
              className="absolute inset-0 m-auto cursor-pointer"
              style={{ width: '45px', height: '45px' }}
            />
          )}
      </div>

      <div className="flex flex-col gap-y-[16px] lg:w-1/2">
        <PrimaryBadge label={eventType} size={'large'} customStyle={'rounded-md w-fit'} />

        <Link
          href={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
          as={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
          passHref
        >
          <a
            onClick={() => {
              dispatch({
                type: 'SET_EVENT_DATA',
                payload: null,
              });
            }}
            className="cursor-pointer !font-satoshi-medium text-[22px] font-semibold"
          >
            {title}
          </a>
        </Link>
        <p className="text-lg">
          <ReactMarkdown
            components={{
              h1: (props) => <h1 {...props} className="mb-4 font-satoshi-bold text-xl" />,
              p: (props) => (
                <p
                  {...props}
                  className="text-justify font-satoshi-medium text-[18px] font-medium  leading-5"
                />
              ),
              a: ({ node, href, children, ...props }) => (
                <a {...props} href={href}>
                  <a className="text-blue-500 font-satoshi-medium text-xl hover:underline">
                    {children}
                  </a>
                </a>
              ),
              ul: ({ node, ...props }) => (
                <ul {...props} className="list-inside list-disc child:text-[18px]" />
              ),
              strong: ({ node, ...props }) => (
                <span {...props} className="font-satoshi-bold text-base font-bold " />
              ),
              li: ({ node, ...props }) => (
                <li {...props} className="text-body !font-satoshi-medium" />
              ),
              ol: ({ node, ...props }) => (
                <ol
                  {...props}
                  className="text-body mb-4 list-inside list-decimal !font-satoshi-medium"
                />
              ),
            }}
          >
            {description}
          </ReactMarkdown>
        </p>
        <p className="text-body flex flex-col font-satoshi-medium">
          <span>{eventDate}</span>
          <span>{category === 'past' ? '' : time}</span>
        </p>
        <div className="grid grid-cols-2 gap-[16px]">
          {category === 'upcoming' ? (
            <>
              <Primary
                label={'Register'}
                size={'large'}
                onClick={() => {
                  dispatch({
                    type: 'SET_EVENT_DATA',
                    payload: {
                      registerLink,
                      eventDate: dateAndTime,
                      startTime,
                      endTime,
                      title,
                      eventType,
                      source: sourceType.eventLisitingPage,
                      eventId,
                      instructor,
                      description,
                    },
                  });

                  dispatch({
                    type: 'SET_WIDGET_SOURCE',
                    payload: sourceType.eventLisitingPage,
                  });
                }}
              />

              {buttonText === 'Explore Event' ? (
                <Secondary
                  linkCTA
                  size={'large'}
                  label={buttonText}
                  href={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
                  onClick={() => {
                    dispatch({
                      type: 'SET_EVENT_DATA',
                      payload: null,
                    });
                  }}
                />
              ) : (
                <Modal slug={`${process.env.NEXT_PUBLIC_APP_BASE_URL}/events/${slug}`} />
              )}
            </>
          ) : (
            <>
              {buttonText === 'Watch Video' && (
                <a
                  type="button"
                  className="w-full cursor-pointer rounded-[10px] border border-red bg-red px-10 py-2 text-center uppercase text-white"
                  // href={''}
                  onClick={openModal}
                >
                  {buttonText}
                </a>
              )}
              {buttonText === 'Explore Event' && (
                <Link
                  href={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
                  as={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
                  passHref
                >
                  <a className="w-full rounded-[10px] border border-red bg-red px-10 py-2 text-center font-satoshi-medium font-semibold uppercase text-white">
                    {buttonText}
                  </a>
                </Link>
              )}
              <Modal slug={`${process.env.NEXT_PUBLIC_APP_BASE_URL}/events/${slug}`} />
            </>
          )}
          {/* {buttonText === 'Register' && (
            <a
              type="button"
              className="px-10 py-2 text-center text-white border rounded-full cursor-pointer border-red bg-red"
              href={queryString ? `${registerLink}?${queryString}` : registerLink}
              target="_blank"
              rel="noreferrer"
            >
              {buttonText}
            </a>
          )}
          {buttonText === 'Watch Video' && (
            <a
              type="button"
              className="px-10 py-2 text-center text-white border rounded-full border-red bg-red"
              href={videoLink}
              target="_blank"
              rel="noreferrer"
            >
              {buttonText}
            </a>
          )}
          {category === 'upcoming' ? (
            <a href={registerLink} target="_blank" rel="noreferrer">
              <button className="w-full px-10 py-2 text-white border rounded-full border-red bg-red">
                Register
              </button>
            </a>
          ) : (
            <>
              {buttonText === 'Explore Event' && (
                <Link
                  href={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
                  as={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
                  passHref
                >
                  <button className="px-10 py-2 text-white border rounded-full border-red bg-red">
                    {buttonText}
                  </button>
                </Link>
              )}
            </>
          )}

          {category === 'upcoming' ? (
            <>
              {buttonText === 'Explore Event' ? (
                <Link
                  href={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
                  as={queryString ? `${path}${slug}?${queryString}` : `${path}${slug}`}
                  passHref
                >
                  <button className="px-10 py-2 border rounded-full border-red text-red">
                    {buttonText}
                  </button>
                </Link>
              ) : (
                <Modal slug={`${process.env.NEXT_PUBLIC_APP_BASE_URL}/events/${slug}`} />
              )}
            </>
          ) : (
            <Modal slug={`${process.env.NEXT_PUBLIC_APP_BASE_URL}/events/${slug}`} />
          )} */}
        </div>
      </div>

      <VideoPopup
        isOpen={isOpen}
        closeModal={closeModal}
        videoLink={recordedVideoLink}
        directPlay
      />
    </div>
  );
};

export default EventsCard;
