import axios from 'axios';

export function getStrapiURL(path = '') {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://localhost:1337'}${path}`;
}

const HIRE_NOW_ENDPOINT = `${process.env.NEXT_PUBLIC_ONBOARDING_ENDPOINT}/contact`;

const OTP_SERVICE_ENDPOINT =
  process.env.NEXT_PUBLIC_OTP_SERVICE_ENDPOINT ||
  'https://2y7jmbekyl.execute-api.ap-south-1.amazonaws.com';

const ONBOARDING_ENDPOINT = process.env.NEXT_PUBLIC_ONBOARDING_ENDPOINT;

export const GROW_DOMAIN =
  process.env.NEXT_PUBLIC_GROW_DOMAIN || 'https://student-dev.almagrow.com';

//  OTP SERVICES
export const SendOTP = async (payload) => {
  return axios
    .post(`${OTP_SERVICE_ENDPOINT}/api/sms/otp/request`, payload)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const VerifyOTP = async (payload) => {
  return axios
    .post(`${OTP_SERVICE_ENDPOINT}/api/sms/otp/verify`, payload)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

// LEAD ONBOARDING API

export const LeadExistsCheck = async (email, source) => {
  return axios
    .get(`${ONBOARDING_ENDPOINT}/api/user/onboarding?email=${email}&source=${source}`)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const LeadOnBoarding = async (payload) => {
  return axios
    .post(`${ONBOARDING_ENDPOINT}/api/user/onboarding`, payload)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

// CONTACT API
export const createContact = async (payload) => {
  return axios
    .post(HIRE_NOW_ENDPOINT, payload)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const updateContact = async (updateBody) => {
  return axios
    .put(HIRE_NOW_ENDPOINT, updateBody)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const JOB_BOARD_COMPILER_BASE_URL = process.env.NEXT_PUBLIC_ONBOARDING_ENDPOINT;

export const COMPILATION_BASE_URL = process.env.NEXT_PUBLIC_COMPILATION_ENDPOINT;

export const LEAD_SQUAD_BASE_URL = process.env.NEXT_PUBLIC_LSQ_API_URL;

export const captureUtm = async (payload) => {
  return axios.post(`${LEAD_SQUAD_BASE_URL}/api/utm-capture`, payload);
};
